<template lang="html">
  <div
    :class="[`vs-checkbox-${color}`, `vs-checkbox-${size}`]"
    class="vs-component con-vs-checkbox"
  >
    <input
      v-bind="$attrs"
      @input="toggleValue($event)"
      :checked="isChecked || $attrs.checked"
      :value="modelValue"
      type="checkbox"
      class="vs-checkbox--input"
    />
    <span :style="style" class="checkbox_x vs-checkbox">
      <span :style="style_check" class="vs-checkbox--check">
        <vs-icon
          :icon="faSquareCheck"
          icon-pack="fa"
          class="vs-checkbox--icon"
        />
      </span>
    </span>
    <span class="con-slot-label pl-2">
      <slot />
    </span>
  </div>
</template>

<script setup>
import { faSquareCheck } from '@fortawesome/pro-solid-svg-icons'
</script>
<script>
import _color from '../../utils/color.js'
export default {
  name: 'VsCheckbox',
  inheritAttrs: false,
  props: {
    color: {
      default: 'primary',
      type: String,
    },
    modelValue: {},
    vsValue: {
      type: [Boolean, Array, String, Number, Object],
      default: false,
    },
    size: {
      default: 'default',
      type: String,
    },
  },
  computed: {
    style_check() {
      return {
        background: this.isChecked ? _color.getColor(this.color, 1) : null,
      }
    },
    style() {
      return {
        border: `2px solid ${
          this.isChecked ? _color.getColor(this.color, 1) : 'rgb(180, 180, 180)'
        }`,
      }
    },
    isChecked() {
      return this.isArrayx() ? this.isArrayIncludes() : this.modelValue
    },
  },
  methods: {
    giveColor(color) {
      return _color.rColor(color)
    },
    toggleValue(evt) {
      if (this.isArrayx()) {
        this.setArray()
      } else if (typeof this.vsValue == 'string') {
        this.setValueString()
      } else {
        this.$emit('update:modelValue', !this.modelValue)
        this.$emit('change', evt)
      }
    },
    setArray() {
      // Copy Array
      const value = this.modelValue.slice(0)
      if (this.isArrayIncludes()) {
        value.splice(value.indexOf(this.vsValue), 1)
        this.$emit('update:modelValue', value)
        this.$emit('change', value)
      } else {
        value.push(this.vsValue)
        this.$emit('update:modelValue', value)
        this.$emit('change', value)
      }
    },
    setValueString() {
      if (this.modelValue == this.vsValue) {
        this.$emit('update:modelValue', null)
        this.$emit('change', null)
      } else {
        this.$emit('update:modelValye', this.vsValue)
        this.$emit('change', this.vsValue)
      }
    },
    isArrayIncludes() {
      let modelx = this.modelValue
      let value = this.vsValue
      return modelx.includes(value)
    },
    isArrayx() {
      return Array.isArray(this.modelValue)
    },
  },
}
</script>
