import { z } from 'zod'

export const setHeaderIfValueExists = (
  headers: Array<Array<string>> | Headers | { [key: string]: string },
  key: string,
  value?: string,
) => {
  if (value) {
    if (Array.isArray(headers)) {
      headers.push([key, value])
    } else if (headers instanceof Headers) {
      headers.set(key, value)
    } else {
      headers[key] = value
    }
  }
}

export const getDefaultHeaderConfig = () => {
  const cookieHeader = useRequestHeader('cookie')
  const headerConfig = {}

  setHeaderIfValueExists(headerConfig, 'cookie', cookieHeader)

  return headerConfig
}

export const mergeToDefaultHeaders = (customHeaders: HeadersInit | undefined) => {
  const headerConfig = getDefaultHeaderConfig()

  if (customHeaders) {
    if (Array.isArray(customHeaders)) {
      customHeaders.forEach(([key, value]) => {
        setHeaderIfValueExists(headerConfig, key, value)
      })
    } else if (customHeaders instanceof Headers) {
      customHeaders.forEach((value, key) => {
        setHeaderIfValueExists(headerConfig, key, value)
      })
    } else {
      Object.entries(customHeaders).forEach(([key, value]) => {
        setHeaderIfValueExists(headerConfig, key, value)
      })
    }
  }

  return headerConfig
}

const warnIfRelativeUrl = (url: string) => {
  if (!url.startsWith('/') && !url.startsWith('http')) {
    console.warn(`[API URL] ${url} is not absolute nor relative to domain (starting by "/"), is it voluntary ?`)
  }
}

export const apiFetch = async <T>(
  { url, options }: {
    url: string,
    options?: Parameters<typeof $fetch>[1]
  }) => {
  warnIfRelativeUrl(url)

  return await $fetch<T>(url, {
    headers: getDefaultHeaderConfig(),
    ...options,
  })
}

export const validatedApiFetch = async <T extends z.ZodTypeAny>(
  { url, schema, options }: {
    url: string,
    options?: Parameters<typeof $fetch>[1]
    schema: T
  }) => {

  warnIfRelativeUrl(url)

  const data = await $fetch(url, {
    headers: getDefaultHeaderConfig(),
    ...options,
  })

  const parsed = schema.safeParse(data)

  if (!parsed.success) {
    console.warn({
      zodError: parsed.error.issues,
      receivedData: data,
    })
    return data as z.infer<T>
  }

  return parsed.data as z.infer<T>
}
