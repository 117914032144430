import { apiFetch, validatedApiFetch } from '@/utils/api/utils'
import { SpaceSchema } from '@/utils/types/space'
import { z } from 'zod'

export const listSpaces = () =>
  validatedApiFetch({
    url: '/api/v1/spaces.json',
    schema: z.array(SpaceSchema),
  })

export const getSpace = (spaceUuid: string) =>
  validatedApiFetch({
    url: `/api/v1/spaces/${spaceUuid}.json`,
    schema: SpaceSchema,
  })

export const getSpaceBySubdomain = (subdomain: string) =>
  validatedApiFetch({
    url: `/api/v1/spaces/url/${subdomain}.json`,
    schema: SpaceSchema,
  })

export const createSpace = (spaceData: FormData) =>
  validatedApiFetch({
    url: `/api/v1/spaces.json`,
    schema: SpaceSchema,
    options: {
      method: 'POST',
      body: spaceData,
    },
  })

export const updateSpace = (spaceUuid: string, spaceData: FormData) =>
  apiFetch({
    url: `/api/v1/spaces/${spaceUuid}.json`,
    options: {
      method: 'PATCH',
      body: spaceData,
    },
  })

export const deleteSpace = (spaceUuid: string) =>
  apiFetch({
    url: `/api/v1/spaces/${spaceUuid}.json`,
    options: {
      method: 'DELETE',
    },
  })

export const toggleDiscardSpace = (spaceUuid: string) =>
  apiFetch({
    url: `/api/v1/spaces/${spaceUuid}/discard.json`,
    options: {
      method: 'DELETE',
    },
  })
