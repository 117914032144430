<template>
  <Dialog
    v-if="dialog"
    v-bind="dialog"
    :opened="true"
  />
</template>
<script setup lang="ts">
import Dialog from '@/components/comeen/utils/popup/Dialog.vue'

const { dialog } = useDialog()
</script>