<template>
  <div
    aria-live="assertive"
    class="z-50 pointer-events-none fixed inset-x-0 bottom-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        v-for="notification in notifications"
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          :class="[
            'pointer-events-auto w-full max-w-sm rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 text-white',
            notification.type === 'success' ? 'bg-comeen-primary-500' : 'bg-comeen-danger',
          ]"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="ml-3 w-0 flex-1">
                <p class="text-sm font-medium">
                  {{ notification.title }}
                </p>
                <p class="mt-1 text-sm" v-if="notification.message">
                  {{ notification.message }}
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0"></div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
const { notifications } = useNotifier()

defineOptions({
  name: 'TheNotifier',
})
</script>
