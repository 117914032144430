import { default as indexpVqG1RgqFLMeta } from "/var/www/html/app/pages/_components/index.vue?macro=true";
import { default as ui0BFiCoyvWuMeta } from "/var/www/html/app/pages/_components/ui.vue?macro=true";
import { default as workplaceN4mIK9v5R0Meta } from "/var/www/html/app/pages/_components/workplace.vue?macro=true";
import { default as adminoYALZvjS7rMeta } from "/var/www/html/app/pages/accounts/admin.vue?macro=true";
import { default as confirmy3pW7SakJdMeta } from "/var/www/html/app/pages/accounts/confirm.vue?macro=true";
import { default as indexZ2dfmmsuJyMeta } from "/var/www/html/app/pages/accounts/grant/[provider]/[domain]/index.vue?macro=true";
import { default as callbackWATArXwU2OMeta } from "/var/www/html/app/pages/accounts/grant/[provider]/callback.vue?macro=true";
import { default as logino0epiAT6jlMeta } from "/var/www/html/app/pages/accounts/login.vue?macro=true";
import { default as logoutD6yhmdxJNIMeta } from "/var/www/html/app/pages/accounts/logout.vue?macro=true";
import { default as authorizeRA73MAyg9XMeta } from "/var/www/html/app/pages/accounts/oauth/authorize.vue?macro=true";
import { default as editvA2X9PpKvmMeta } from "/var/www/html/app/pages/admin/admins/[id]/edit.vue?macro=true";
import { default as indexdwMWNvQURVMeta } from "/var/www/html/app/pages/admin/admins/[id]/index.vue?macro=true";
import { default as indexa6jUlSLFqtMeta } from "/var/www/html/app/pages/admin/admins/index.vue?macro=true";
import { default as newaX72PJXf4dMeta } from "/var/www/html/app/pages/admin/admins/new.vue?macro=true";
import { default as settingsC3kE7vafN8Meta } from "/var/www/html/app/pages/admin/admins/settings.vue?macro=true";
import { default as indexFYgfKJ4vKOMeta } from "/var/www/html/app/pages/admin/index.vue?macro=true";
import { default as settingstGRwQut6VXMeta } from "/var/www/html/app/pages/admin/settings.vue?macro=true";
import { default as editvIVVj9oM7xMeta } from "/var/www/html/app/pages/admin/spaces/[id]/edit.vue?macro=true";
import { default as indexGV9ktyUTkIMeta } from "/var/www/html/app/pages/admin/spaces/[id]/index.vue?macro=true";
import { default as indexW8dY9GP8RqMeta } from "/var/www/html/app/pages/admin/spaces/index.vue?macro=true";
import { default as newvXI2cIrLcyMeta } from "/var/www/html/app/pages/admin/spaces/new.vue?macro=true";
import { default as index_46clientSD2qLNFE1nMeta } from "/var/www/html/app/pages/my/book/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/var/www/html/app/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as map4uNYTCpr8CMeta } from "/var/www/html/app/pages/my/book/map.vue?macro=true";
import { default as clientside_session_testsoYaXo9fxPRMeta } from "/var/www/html/app/pages/my/clientside_session_tests.vue?macro=true";
import { default as _91name_93wOWAPnslPMMeta } from "/var/www/html/app/pages/my/desks/[name].vue?macro=true";
import { default as dialog_testsZbRtGBYTbvMeta } from "/var/www/html/app/pages/my/dialog_tests.vue?macro=true";
import { default as _91uuid_93LwFjCEqFbGMeta } from "/var/www/html/app/pages/my/direct/map_booking/[uuid].vue?macro=true";
import { default as indexcKgKaYJmxiMeta } from "/var/www/html/app/pages/my/index.vue?macro=true";
import { default as leaflet_testsTvgeJMeVCdMeta } from "/var/www/html/app/pages/my/leaflet_tests.vue?macro=true";
import { default as loader_testsp9x87CdpSwMeta } from "/var/www/html/app/pages/my/loader_tests.vue?macro=true";
import { default as loginUdrcHjFG7eMeta } from "/var/www/html/app/pages/my/login.vue?macro=true";
import { default as officefkfCK2oZbqMeta } from "/var/www/html/app/pages/my/office.vue?macro=true";
import { default as _91uuid_93jlDvbzQUZcMeta } from "/var/www/html/app/pages/my/rooms/[uuid].vue?macro=true";
import { default as scheduleJ6exmN8iRdMeta } from "/var/www/html/app/pages/my/schedule.vue?macro=true";
import { default as settingsZ5nGdzb4fTMeta } from "/var/www/html/app/pages/my/settings.vue?macro=true";
import { default as book_46clientOwHD2RX1vbMeta } from "/var/www/html/app/pages/my/team/book.client.vue?macro=true";
import { default as indexny6N8mes48Meta } from "/var/www/html/app/pages/my/team/index.vue?macro=true";
import { default as insights0n8n0YtGNyMeta } from "/var/www/html/app/pages/my/team/insights.vue?macro=true";
import { default as presencesDTjsKyjUgPMeta } from "/var/www/html/app/pages/my/team/manager/presences.vue?macro=true";
import { default as settingsBFHP1svKU1Meta } from "/var/www/html/app/pages/my/team/settings.vue?macro=true";
import { default as editgQHZaAujwcMeta } from "/var/www/html/app/pages/userspaces/announcements/[id]/edit.vue?macro=true";
import { default as indexo3WeyLEinYMeta } from "/var/www/html/app/pages/userspaces/announcements/index.vue?macro=true";
import { default as newEWgXWKBskTMeta } from "/var/www/html/app/pages/userspaces/announcements/new.vue?macro=true";
import { default as indexP52eARQSDLMeta } from "/var/www/html/app/pages/userspaces/bookables/[id]/index.vue?macro=true";
import { default as sync3YVyf48qAuMeta } from "/var/www/html/app/pages/userspaces/bookables/[id]/sync.vue?macro=true";
import { default as areas_46clientVHVbG5suY9Meta } from "/var/www/html/app/pages/userspaces/buildings/[buildingid]/floors/[id]/areas.client.vue?macro=true";
import { default as desks_46clientCEn4BbMY9ZMeta } from "/var/www/html/app/pages/userspaces/buildings/[buildingid]/floors/[id]/desks.client.vue?macro=true";
import { default as rooms_46clientw5txd0htYMMeta } from "/var/www/html/app/pages/userspaces/buildings/[buildingid]/floors/[id]/rooms.client.vue?macro=true";
import { default as editI9wXRU5UHPMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/edit.vue?macro=true";
import { default as bookablesLB86zZkscqMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/bookables.vue?macro=true";
import { default as desksNbIoPYtSk8Meta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/desks.vue?macro=true";
import { default as floorsnhvit6bqzEMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/floors.vue?macro=true";
import { default as indexk3qW6cymlVMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/index.vue?macro=true";
import { default as editIOo0i4S4AhMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/kiosks/[kioskid]/edit.vue?macro=true";
import { default as indexSf1Oq8iHfNMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/kiosks/[kioskid]/index.vue?macro=true";
import { default as index39ZErpAjSGMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/kiosks/index.vue?macro=true";
import { default as newTMXJgZbSc8Meta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/kiosks/new.vue?macro=true";
import { default as mapA7gp3zRyeNMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/map.vue?macro=true";
import { default as meeting_roomsrjW7iB121SMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/meeting_rooms.vue?macro=true";
import { default as occupancyziQX57dS0YMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/occupancy.vue?macro=true";
import { default as presencesCYMnavsm87Meta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/presences.vue?macro=true";
import { default as room_policylcHf39CQqaMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/room_policy.vue?macro=true";
import { default as seat_policyQYYeWhKlRMMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index/seat_policy.vue?macro=true";
import { default as indexfPz43Jr3TEMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/index.vue?macro=true";
import { default as sync_45desks3AwGvRQQoSMeta } from "/var/www/html/app/pages/userspaces/buildings/[id]/sync-desks.vue?macro=true";
import { default as googleCycUt0ADqYMeta } from "/var/www/html/app/pages/userspaces/buildings/import/google.vue?macro=true";
import { default as indexSgjyOrk5xNMeta } from "/var/www/html/app/pages/userspaces/buildings/index.vue?macro=true";
import { default as newKmDFFWTeYKMeta } from "/var/www/html/app/pages/userspaces/buildings/new.vue?macro=true";
import { default as visitorsikIgfQd2uBMeta } from "/var/www/html/app/pages/userspaces/imports/visitors.vue?macro=true";
import { default as index0CMLMtpxQNMeta } from "/var/www/html/app/pages/userspaces/index.vue?macro=true";
import { default as presences_46clientCQrSzopsHUMeta } from "/var/www/html/app/pages/userspaces/insights/presences.client.vue?macro=true";
import { default as login3PpziTs0wLMeta } from "/var/www/html/app/pages/userspaces/login.vue?macro=true";
import { default as indexXJlGtfueUIMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/[id]/index.vue?macro=true";
import { default as automatic_45meeting_45workflowKRBY3Cr2FvMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/[id]/manage/automatic-meeting-workflow.vue?macro=true";
import { default as indexfYS05l2n6wMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/[id]/manage/index.vue?macro=true";
import { default as manageQsFthPOEqbMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/[id]/manage.vue?macro=true";
import { default as meetingsIJFybLahx6Meta } from "/var/www/html/app/pages/userspaces/meeting_rooms/[id]/meetings.vue?macro=true";
import { default as _91id_93705WBAMQzNMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/[id].vue?macro=true";
import { default as detailsokG9F8GpauMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/[id]/details.vue?macro=true";
import { default as editNZ7IedBGFkMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/[id]/edit.vue?macro=true";
import { default as indexGnfgJCunvuMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/[id]/index.vue?macro=true";
import { default as indexm4jKhLHp0tMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/index.vue?macro=true";
import { default as newuxpM5dL9OAMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/new.vue?macro=true";
import { default as google88koIGVJW9Meta } from "/var/www/html/app/pages/userspaces/meeting_rooms/import/google.vue?macro=true";
import { default as microsoftfYH0WfxVLQMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/import/microsoft.vue?macro=true";
import { default as indexvmwDnmbANKMeta } from "/var/www/html/app/pages/userspaces/meeting_rooms/index.vue?macro=true";
import { default as google_45calendarkWfwzl12PoMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/apps/google-calendar.vue?macro=true";
import { default as indexZCXKhzS1PlMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/apps/index.vue?macro=true";
import { default as microsoft_45teamspcET3fqXJwMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/apps/microsoft-teams.vue?macro=true";
import { default as slackX2MKLgjlNHMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/apps/slack.vue?macro=true";
import { default as beyondulYF8PJOvnMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/beyond.vue?macro=true";
import { default as booking7xu4tn1JXDMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/demo/booking.vue?macro=true";
import { default as indexZDac0nnxhsMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/demo/index.vue?macro=true";
import { default as finish7ov11YtrgtMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/finish.vue?macro=true";
import { default as index6WTIdvTGmnMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/index.vue?macro=true";
import { default as working_45locationzDbdGj4U8AMeta } from "/var/www/html/app/pages/userspaces/onboarding/attendance/scope/working-location.vue?macro=true";
import { default as indexF0j0fTILzFMeta } from "/var/www/html/app/pages/userspaces/onboarding/index.vue?macro=true";
import { default as beyond4tcyNHLitpMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/beyond.vue?macro=true";
import { default as indexmSOlYbOdNLMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/buildings/index.vue?macro=true";
import { default as chatbhmNrwVPeWMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/chat.vue?macro=true";
import { default as finishl0Q6ltgoOMMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/finish.vue?macro=true";
import { default as indexh9UlEZyVlXMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/index.vue?macro=true";
import { default as buildings805sZms1WCMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/scope/buildings.vue?macro=true";
import { default as events6eD9DtMIc1Meta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/scope/events.vue?macro=true";
import { default as roomsmrHkRFdAxBMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/scope/rooms.vue?macro=true";
import { default as buildingsuzgWCwVvQLMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/sync/buildings.vue?macro=true";
import { default as roomsQ2wHlJcV5TMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/sync/rooms.vue?macro=true";
import { default as workflowlUdhUENHZlMeta } from "/var/www/html/app/pages/userspaces/onboarding/rooms/workflow.vue?macro=true";
import { default as indexXY6Jn86FvhMeta } from "/var/www/html/app/pages/userspaces/qr/guests/[qr]/index.vue?macro=true";
import { default as indexZfnQjrAWUmMeta } from "/var/www/html/app/pages/userspaces/settings/attendance/index.vue?macro=true";
import { default as editMR1BlbM47OMeta } from "/var/www/html/app/pages/userspaces/settings/attendance/rules/[id]/edit.vue?macro=true";
import { default as indexQcQ360cGYhMeta } from "/var/www/html/app/pages/userspaces/settings/attendance/rules/index.vue?macro=true";
import { default as newU91r7ww7GiMeta } from "/var/www/html/app/pages/userspaces/settings/attendance/rules/new.vue?macro=true";
import { default as globalemNtDLHia4Meta } from "/var/www/html/app/pages/userspaces/settings/attendance/seat_policy/global.vue?macro=true";
import { default as bookablesJQTWb7QF9dMeta } from "/var/www/html/app/pages/userspaces/settings/bookables.vue?macro=true";
import { default as dataZOcvCPVDMgMeta } from "/var/www/html/app/pages/userspaces/settings/data.vue?macro=true";
import { default as desksKxzHddkzdVMeta } from "/var/www/html/app/pages/userspaces/settings/desks.vue?macro=true";
import { default as indexs3yW99PRi8Meta } from "/var/www/html/app/pages/userspaces/settings/index.vue?macro=true";
import { default as integrationsncNyD3RVI5Meta } from "/var/www/html/app/pages/userspaces/settings/integrations.vue?macro=true";
import { default as indexd78t3oDuZ0Meta } from "/var/www/html/app/pages/userspaces/settings/kiosks/index.vue?macro=true";
import { default as editPs3MH5gsjDMeta } from "/var/www/html/app/pages/userspaces/settings/kiosks/profiles/[id]/edit.vue?macro=true";
import { default as indexa7y2c4JywAMeta } from "/var/www/html/app/pages/userspaces/settings/kiosks/profiles/index.vue?macro=true";
import { default as newqnFAwAzhgpMeta } from "/var/www/html/app/pages/userspaces/settings/kiosks/profiles/new.vue?macro=true";
import { default as edit8mfpBtHIk4Meta } from "/var/www/html/app/pages/userspaces/settings/kiosks/themes/[id]/edit.vue?macro=true";
import { default as indexG3UR25oWeLMeta } from "/var/www/html/app/pages/userspaces/settings/kiosks/themes/index.vue?macro=true";
import { default as newquvdWD5GSjMeta } from "/var/www/html/app/pages/userspaces/settings/kiosks/themes/new.vue?macro=true";
import { default as rooms4S54YOqUTCMeta } from "/var/www/html/app/pages/userspaces/settings/rooms.vue?macro=true";
import { default as usersimportQh72R4OIkRMeta } from "/var/www/html/app/pages/userspaces/settings/usersimport.vue?macro=true";
import { default as editw261nhP8PSMeta } from "/var/www/html/app/pages/userspaces/users/[id]/edit.vue?macro=true";
import { default as indexvhVIfEZ5FmMeta } from "/var/www/html/app/pages/userspaces/users/[id]/index.vue?macro=true";
import { default as edit5zVjkH2JDKMeta } from "/var/www/html/app/pages/userspaces/users/groups/[id]/edit.vue?macro=true";
import { default as indexpNN0SyrEsFMeta } from "/var/www/html/app/pages/userspaces/users/groups/[id]/index.vue?macro=true";
import { default as googleJIdzPmdAsQMeta } from "/var/www/html/app/pages/userspaces/users/groups/import/google.vue?macro=true";
import { default as microsoftLRq44f1lzDMeta } from "/var/www/html/app/pages/userspaces/users/groups/import/microsoft.vue?macro=true";
import { default as indexAabbnP4APUMeta } from "/var/www/html/app/pages/userspaces/users/groups/index.vue?macro=true";
import { default as newle9I5p60WXMeta } from "/var/www/html/app/pages/userspaces/users/groups/new.vue?macro=true";
import { default as indexi7ogBEc304Meta } from "/var/www/html/app/pages/userspaces/users/index.vue?macro=true";
import { default as newyQihkX2wGBMeta } from "/var/www/html/app/pages/userspaces/users/new.vue?macro=true";
import { default as _91id_93Aa9LeFVOc9Meta } from "/var/www/html/app/pages/userspaces/visitors/index/[id].vue?macro=true";
import { default as registerpKR0kfBcePMeta } from "/var/www/html/app/pages/userspaces/visitors/index/register.vue?macro=true";
import { default as indexAMhGx9Dz00Meta } from "/var/www/html/app/pages/userspaces/visitors/index.vue?macro=true";
export default [
  {
    name: "_components",
    path: "/_components",
    meta: { ...(indexpVqG1RgqFLMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/_components/index.vue")
  },
  {
    name: "_components-ui",
    path: "/_components/ui",
    meta: { ...(ui0BFiCoyvWuMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/_components/ui.vue")
  },
  {
    name: "_components-workplace",
    path: "/_components/workplace",
    meta: { ...(workplaceN4mIK9v5R0Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/_components/workplace.vue")
  },
  {
    name: "accounts-admin",
    path: "/accounts/admin",
    meta: { ...(adminoYALZvjS7rMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/accounts/admin.vue")
  },
  {
    name: "accounts-confirm",
    path: "/accounts/confirm",
    meta: { ...(confirmy3pW7SakJdMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/accounts/confirm.vue")
  },
  {
    name: "accounts-grant-provider-domain",
    path: "/accounts/grant/:provider()/:domain()",
    meta: { ...(indexZ2dfmmsuJyMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/accounts/grant/[provider]/[domain]/index.vue")
  },
  {
    name: "accounts-grant-provider-callback",
    path: "/accounts/grant/:provider()/callback",
    meta: { ...(callbackWATArXwU2OMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/accounts/grant/[provider]/callback.vue")
  },
  {
    name: "accounts-login",
    path: "/accounts/login",
    meta: { ...(logino0epiAT6jlMeta || {}), ...{"middleware":["policies"]} },
    alias: ["/"],
    component: () => import("/var/www/html/app/pages/accounts/login.vue")
  },
  {
    name: "accounts-logout",
    path: "/accounts/logout",
    meta: { ...(logoutD6yhmdxJNIMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/accounts/logout.vue")
  },
  {
    name: "accounts-oauth-authorize",
    path: "/accounts/oauth/authorize",
    meta: { ...(authorizeRA73MAyg9XMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/accounts/oauth/authorize.vue")
  },
  {
    name: "admin-admins-id-edit",
    path: "/admin/admins/:id()/edit",
    meta: { ...(editvA2X9PpKvmMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/admins/[id]/edit.vue")
  },
  {
    name: "admin-admins-id",
    path: "/admin/admins/:id()",
    meta: { ...(indexdwMWNvQURVMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/admins/[id]/index.vue")
  },
  {
    name: "admin-admins",
    path: "/admin/admins",
    meta: { ...(indexa6jUlSLFqtMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/admins/index.vue")
  },
  {
    name: "admin-admins-new",
    path: "/admin/admins/new",
    meta: { ...(newaX72PJXf4dMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/admins/new.vue")
  },
  {
    name: "admin-admins-settings",
    path: "/admin/admins/settings",
    meta: { ...(settingsC3kE7vafN8Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/admins/settings.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: { ...(indexFYgfKJ4vKOMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/index.vue")
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: { ...(settingstGRwQut6VXMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/settings.vue")
  },
  {
    name: "admin-spaces-id-edit",
    path: "/admin/spaces/:id()/edit",
    meta: { ...(editvIVVj9oM7xMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/spaces/[id]/edit.vue")
  },
  {
    name: "admin-spaces-id",
    path: "/admin/spaces/:id()",
    meta: { ...(indexGV9ktyUTkIMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/spaces/[id]/index.vue")
  },
  {
    name: "admin-spaces",
    path: "/admin/spaces",
    meta: { ...(indexW8dY9GP8RqMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/spaces/index.vue")
  },
  {
    name: "admin-spaces-new",
    path: "/admin/spaces/new",
    meta: { ...(newvXI2cIrLcyMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/admin/spaces/new.vue")
  },
  {
    name: "my-book",
    path: "/my/book",
    meta: { ...(index_46clientSD2qLNFE1nMeta || {}), ...{"middleware":["policies"]} },
    component: () => createClientPage(() => import("/var/www/html/app/pages/my/book/index.client.vue"))
  },
  {
    name: "my-book-map",
    path: "/my/book/map",
    meta: { ...(map4uNYTCpr8CMeta || {}), ...{"middleware":["policies"]} },
    alias: ["/book/map"],
    component: () => import("/var/www/html/app/pages/my/book/map.vue")
  },
  {
    name: "my-clientside_session_tests",
    path: "/my/clientside_session_tests",
    meta: { ...(clientside_session_testsoYaXo9fxPRMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/clientside_session_tests.vue")
  },
  {
    name: "my-desks-name",
    path: "/my/desks/:name()",
    meta: { ...(_91name_93wOWAPnslPMMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/desks/[name].vue")
  },
  {
    name: "my-dialog_tests",
    path: "/my/dialog_tests",
    meta: { ...(dialog_testsZbRtGBYTbvMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/dialog_tests.vue")
  },
  {
    name: "my-direct-map_booking-uuid",
    path: "/my/direct/map_booking/:uuid()",
    meta: { ...(_91uuid_93LwFjCEqFbGMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/direct/map_booking/[uuid].vue")
  },
  {
    name: "my",
    path: "/my",
    meta: { ...(indexcKgKaYJmxiMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/index.vue")
  },
  {
    name: "my-leaflet_tests",
    path: "/my/leaflet_tests",
    meta: { ...(leaflet_testsTvgeJMeVCdMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/leaflet_tests.vue")
  },
  {
    name: "my-loader_tests",
    path: "/my/loader_tests",
    meta: { ...(loader_testsp9x87CdpSwMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/loader_tests.vue")
  },
  {
    name: "my-login",
    path: "/my/login",
    meta: { ...(loginUdrcHjFG7eMeta || {}), ...{"middleware":["policies"]} },
    alias: ["/"],
    component: () => import("/var/www/html/app/pages/my/login.vue")
  },
  {
    name: "my-office",
    path: "/my/office",
    meta: { ...(officefkfCK2oZbqMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/office.vue")
  },
  {
    name: "my-rooms-uuid",
    path: "/my/rooms/:uuid()",
    meta: { ...(_91uuid_93jlDvbzQUZcMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/rooms/[uuid].vue")
  },
  {
    name: "my-schedule",
    path: "/my/schedule",
    meta: { ...(scheduleJ6exmN8iRdMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/schedule.vue")
  },
  {
    name: "my-settings",
    path: "/my/settings",
    meta: { ...(settingsZ5nGdzb4fTMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/settings.vue")
  },
  {
    name: "my-team-book",
    path: "/my/team/book",
    meta: { ...(book_46clientOwHD2RX1vbMeta || {}), ...{"middleware":["policies"]} },
    component: () => createClientPage(() => import("/var/www/html/app/pages/my/team/book.client.vue"))
  },
  {
    name: "my-team",
    path: "/my/team",
    meta: { ...(indexny6N8mes48Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/team/index.vue")
  },
  {
    name: "my-team-insights",
    path: "/my/team/insights",
    meta: { ...(insights0n8n0YtGNyMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/team/insights.vue")
  },
  {
    name: "my-team-manager-presences",
    path: "/my/team/manager/presences",
    meta: { ...(presencesDTjsKyjUgPMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/my/team/manager/presences.vue")
  },
  {
    name: "my-team-settings",
    path: "/my/team/settings",
    meta: { ...(settingsBFHP1svKU1Meta || {}), ...{"middleware":["policies"]} },
    alias: ["/team/settings"],
    component: () => import("/var/www/html/app/pages/my/team/settings.vue")
  },
  {
    name: "userspaces-announcements-id-edit",
    path: "/userspaces/announcements/:id()/edit",
    meta: { ...(editgQHZaAujwcMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/announcements/[id]/edit.vue")
  },
  {
    name: "userspaces-announcements",
    path: "/userspaces/announcements",
    meta: { ...(indexo3WeyLEinYMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/announcements/index.vue")
  },
  {
    name: "userspaces-announcements-new",
    path: "/userspaces/announcements/new",
    meta: { ...(newEWgXWKBskTMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/announcements/new.vue")
  },
  {
    name: "userspaces-bookables-id",
    path: "/userspaces/bookables/:id()",
    meta: { ...(indexP52eARQSDLMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/bookables/[id]/index.vue")
  },
  {
    name: "userspaces-bookables-id-sync",
    path: "/userspaces/bookables/:id()/sync",
    meta: { ...(sync3YVyf48qAuMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/bookables/[id]/sync.vue")
  },
  {
    name: "userspaces-buildings-buildingid-floors-id-areas",
    path: "/userspaces/buildings/:buildingid()/floors/:id()/areas",
    meta: { ...(areas_46clientVHVbG5suY9Meta || {}), ...{"middleware":["policies"]} },
    component: () => createClientPage(() => import("/var/www/html/app/pages/userspaces/buildings/[buildingid]/floors/[id]/areas.client.vue"))
  },
  {
    name: "userspaces-buildings-buildingid-floors-id-desks",
    path: "/userspaces/buildings/:buildingid()/floors/:id()/desks",
    meta: { ...(desks_46clientCEn4BbMY9ZMeta || {}), ...{"middleware":["policies"]} },
    component: () => createClientPage(() => import("/var/www/html/app/pages/userspaces/buildings/[buildingid]/floors/[id]/desks.client.vue"))
  },
  {
    name: "userspaces-buildings-buildingid-floors-id-rooms",
    path: "/userspaces/buildings/:buildingid()/floors/:id()/rooms",
    meta: { ...(rooms_46clientw5txd0htYMMeta || {}), ...{"middleware":["policies"]} },
    component: () => createClientPage(() => import("/var/www/html/app/pages/userspaces/buildings/[buildingid]/floors/[id]/rooms.client.vue"))
  },
  {
    name: "userspaces-buildings-id-edit",
    path: "/userspaces/buildings/:id()/edit",
    meta: { ...(editI9wXRU5UHPMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/edit.vue")
  },
  {
    name: indexfPz43Jr3TEMeta?.name,
    path: "/userspaces/buildings/:id()",
    meta: { ...(indexfPz43Jr3TEMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index.vue"),
    children: [
  {
    name: "userspaces-buildings-id-index-bookables",
    path: "bookables",
    meta: { ...(bookablesLB86zZkscqMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/bookables.vue")
  },
  {
    name: "userspaces-buildings-id-index-desks",
    path: "desks",
    meta: { ...(desksNbIoPYtSk8Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/desks.vue")
  },
  {
    name: "userspaces-buildings-id-index-floors",
    path: "floors",
    meta: { ...(floorsnhvit6bqzEMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/floors.vue")
  },
  {
    name: "userspaces-buildings-id-index",
    path: "",
    meta: { ...(indexk3qW6cymlVMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/index.vue")
  },
  {
    name: "userspaces-buildings-id-index-kiosks-kioskid-edit",
    path: "kiosks/:kioskid()/edit",
    meta: { ...(editIOo0i4S4AhMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/kiosks/[kioskid]/edit.vue")
  },
  {
    name: "userspaces-buildings-id-index-kiosks-kioskid",
    path: "kiosks/:kioskid()",
    meta: { ...(indexSf1Oq8iHfNMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/kiosks/[kioskid]/index.vue")
  },
  {
    name: "userspaces-buildings-id-index-kiosks",
    path: "kiosks",
    meta: { ...(index39ZErpAjSGMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/kiosks/index.vue")
  },
  {
    name: "userspaces-buildings-id-index-kiosks-new",
    path: "kiosks/new",
    meta: { ...(newTMXJgZbSc8Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/kiosks/new.vue")
  },
  {
    name: "userspaces-buildings-id-index-map",
    path: "map",
    meta: { ...(mapA7gp3zRyeNMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/map.vue")
  },
  {
    name: "userspaces-buildings-id-index-meeting_rooms",
    path: "meeting_rooms",
    meta: { ...(meeting_roomsrjW7iB121SMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/meeting_rooms.vue")
  },
  {
    name: "userspaces-buildings-id-index-occupancy",
    path: "occupancy",
    meta: { ...(occupancyziQX57dS0YMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/occupancy.vue")
  },
  {
    name: "userspaces-buildings-id-index-presences",
    path: "presences",
    meta: { ...(presencesCYMnavsm87Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/presences.vue")
  },
  {
    name: "userspaces-buildings-id-index-room_policy",
    path: "room_policy",
    meta: { ...(room_policylcHf39CQqaMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/room_policy.vue")
  },
  {
    name: "userspaces-buildings-id-index-seat_policy",
    path: "seat_policy",
    meta: { ...(seat_policyQYYeWhKlRMMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/index/seat_policy.vue")
  }
]
  },
  {
    name: "userspaces-buildings-id-sync-desks",
    path: "/userspaces/buildings/:id()/sync-desks",
    meta: { ...(sync_45desks3AwGvRQQoSMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/[id]/sync-desks.vue")
  },
  {
    name: "userspaces-buildings-import-google",
    path: "/userspaces/buildings/import/google",
    meta: { ...(googleCycUt0ADqYMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/import/google.vue")
  },
  {
    name: "userspaces-buildings",
    path: "/userspaces/buildings",
    meta: { ...(indexSgjyOrk5xNMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/index.vue")
  },
  {
    name: "userspaces-buildings-new",
    path: "/userspaces/buildings/new",
    meta: { ...(newKmDFFWTeYKMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/buildings/new.vue")
  },
  {
    name: "userspaces-imports-visitors",
    path: "/userspaces/imports/visitors",
    meta: { ...(visitorsikIgfQd2uBMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/imports/visitors.vue")
  },
  {
    name: "userspaces",
    path: "/userspaces",
    meta: { ...(index0CMLMtpxQNMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/index.vue")
  },
  {
    name: "userspaces-insights-presences",
    path: "/userspaces/insights/presences",
    meta: { ...(presences_46clientCQrSzopsHUMeta || {}), ...{"middleware":["policies"]} },
    component: () => createClientPage(() => import("/var/www/html/app/pages/userspaces/insights/presences.client.vue"))
  },
  {
    name: "userspaces-login",
    path: "/userspaces/login",
    meta: { ...(login3PpziTs0wLMeta || {}), ...{"middleware":["policies"]} },
    alias: ["/"],
    component: () => import("/var/www/html/app/pages/userspaces/login.vue")
  },
  {
    name: _91id_93705WBAMQzNMeta?.name,
    path: "/userspaces/meeting_rooms/:id()",
    meta: { ...(_91id_93705WBAMQzNMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/[id].vue"),
    children: [
  {
    name: "userspaces-meeting_rooms-id",
    path: "",
    meta: { ...(indexXJlGtfueUIMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/[id]/index.vue")
  },
  {
    name: manageQsFthPOEqbMeta?.name,
    path: "manage",
    meta: { ...(manageQsFthPOEqbMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/[id]/manage.vue"),
    children: [
  {
    name: "userspaces-meeting_rooms-id-manage-automatic-meeting-workflow",
    path: "automatic-meeting-workflow",
    meta: { ...(automatic_45meeting_45workflowKRBY3Cr2FvMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/[id]/manage/automatic-meeting-workflow.vue")
  },
  {
    name: "userspaces-meeting_rooms-id-manage",
    path: "",
    meta: { ...(indexfYS05l2n6wMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/[id]/manage/index.vue")
  }
]
  },
  {
    name: "userspaces-meeting_rooms-id-meetings",
    path: "meetings",
    meta: { ...(meetingsIJFybLahx6Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/[id]/meetings.vue")
  }
]
  },
  {
    name: "userspaces-meeting_rooms-dashboards-id-details",
    path: "/userspaces/meeting_rooms/dashboards/:id()/details",
    meta: { ...(detailsokG9F8GpauMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/[id]/details.vue")
  },
  {
    name: "userspaces-meeting_rooms-dashboards-id-edit",
    path: "/userspaces/meeting_rooms/dashboards/:id()/edit",
    meta: { ...(editNZ7IedBGFkMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/[id]/edit.vue")
  },
  {
    name: "userspaces-meeting_rooms-dashboards-id",
    path: "/userspaces/meeting_rooms/dashboards/:id()",
    meta: { ...(indexGnfgJCunvuMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/[id]/index.vue")
  },
  {
    name: "userspaces-meeting_rooms-dashboards",
    path: "/userspaces/meeting_rooms/dashboards",
    meta: { ...(indexm4jKhLHp0tMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/index.vue")
  },
  {
    name: "userspaces-meeting_rooms-dashboards-new",
    path: "/userspaces/meeting_rooms/dashboards/new",
    meta: { ...(newuxpM5dL9OAMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/dashboards/new.vue")
  },
  {
    name: "userspaces-meeting_rooms-import-google",
    path: "/userspaces/meeting_rooms/import/google",
    meta: { ...(google88koIGVJW9Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/import/google.vue")
  },
  {
    name: "userspaces-meeting_rooms-import-microsoft",
    path: "/userspaces/meeting_rooms/import/microsoft",
    meta: { ...(microsoftfYH0WfxVLQMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/import/microsoft.vue")
  },
  {
    name: "userspaces-meeting_rooms",
    path: "/userspaces/meeting_rooms",
    meta: { ...(indexvmwDnmbANKMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/meeting_rooms/index.vue")
  },
  {
    name: "userspaces-onboarding-attendance-apps-google-calendar",
    path: "/userspaces/onboarding/attendance/apps/google-calendar",
    meta: { ...(google_45calendarkWfwzl12PoMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/apps/google-calendar.vue")
  },
  {
    name: "userspaces-onboarding-attendance-apps",
    path: "/userspaces/onboarding/attendance/apps",
    meta: { ...(indexZCXKhzS1PlMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/apps/index.vue")
  },
  {
    name: "userspaces-onboarding-attendance-apps-microsoft-teams",
    path: "/userspaces/onboarding/attendance/apps/microsoft-teams",
    meta: { ...(microsoft_45teamspcET3fqXJwMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/apps/microsoft-teams.vue")
  },
  {
    name: "userspaces-onboarding-attendance-apps-slack",
    path: "/userspaces/onboarding/attendance/apps/slack",
    meta: { ...(slackX2MKLgjlNHMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/apps/slack.vue")
  },
  {
    name: "userspaces-onboarding-attendance-beyond",
    path: "/userspaces/onboarding/attendance/beyond",
    meta: { ...(beyondulYF8PJOvnMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/beyond.vue")
  },
  {
    name: "userspaces-onboarding-attendance-demo-booking",
    path: "/userspaces/onboarding/attendance/demo/booking",
    meta: { ...(booking7xu4tn1JXDMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/demo/booking.vue")
  },
  {
    name: "userspaces-onboarding-attendance-demo",
    path: "/userspaces/onboarding/attendance/demo",
    meta: { ...(indexZDac0nnxhsMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/demo/index.vue")
  },
  {
    name: "userspaces-onboarding-attendance-finish",
    path: "/userspaces/onboarding/attendance/finish",
    meta: { ...(finish7ov11YtrgtMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/finish.vue")
  },
  {
    name: "userspaces-onboarding-attendance",
    path: "/userspaces/onboarding/attendance",
    meta: { ...(index6WTIdvTGmnMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/index.vue")
  },
  {
    name: "userspaces-onboarding-attendance-scope-working-location",
    path: "/userspaces/onboarding/attendance/scope/working-location",
    meta: { ...(working_45locationzDbdGj4U8AMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/attendance/scope/working-location.vue")
  },
  {
    name: "userspaces-onboarding",
    path: "/userspaces/onboarding",
    meta: { ...(indexF0j0fTILzFMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/index.vue")
  },
  {
    name: "userspaces-onboarding-rooms-beyond",
    path: "/userspaces/onboarding/rooms/beyond",
    meta: { ...(beyond4tcyNHLitpMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/beyond.vue")
  },
  {
    name: "userspaces-onboarding-rooms-buildings",
    path: "/userspaces/onboarding/rooms/buildings",
    meta: { ...(indexmSOlYbOdNLMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/buildings/index.vue")
  },
  {
    name: "userspaces-onboarding-rooms-chat",
    path: "/userspaces/onboarding/rooms/chat",
    meta: { ...(chatbhmNrwVPeWMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/chat.vue")
  },
  {
    name: "userspaces-onboarding-rooms-finish",
    path: "/userspaces/onboarding/rooms/finish",
    meta: { ...(finishl0Q6ltgoOMMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/finish.vue")
  },
  {
    name: "userspaces-onboarding-rooms",
    path: "/userspaces/onboarding/rooms",
    meta: { ...(indexh9UlEZyVlXMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/index.vue")
  },
  {
    name: "userspaces-onboarding-rooms-scope-buildings",
    path: "/userspaces/onboarding/rooms/scope/buildings",
    meta: { ...(buildings805sZms1WCMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/scope/buildings.vue")
  },
  {
    name: "userspaces-onboarding-rooms-scope-events",
    path: "/userspaces/onboarding/rooms/scope/events",
    meta: { ...(events6eD9DtMIc1Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/scope/events.vue")
  },
  {
    name: "userspaces-onboarding-rooms-scope-rooms",
    path: "/userspaces/onboarding/rooms/scope/rooms",
    meta: { ...(roomsmrHkRFdAxBMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/scope/rooms.vue")
  },
  {
    name: "userspaces-onboarding-rooms-sync-buildings",
    path: "/userspaces/onboarding/rooms/sync/buildings",
    meta: { ...(buildingsuzgWCwVvQLMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/sync/buildings.vue")
  },
  {
    name: "userspaces-onboarding-rooms-sync-rooms",
    path: "/userspaces/onboarding/rooms/sync/rooms",
    meta: { ...(roomsQ2wHlJcV5TMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/sync/rooms.vue")
  },
  {
    name: "userspaces-onboarding-rooms-workflow",
    path: "/userspaces/onboarding/rooms/workflow",
    meta: { ...(workflowlUdhUENHZlMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/onboarding/rooms/workflow.vue")
  },
  {
    name: "userspaces-qr-guests-qr",
    path: "/userspaces/qr/guests/:qr()",
    meta: { ...(indexXY6Jn86FvhMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/qr/guests/[qr]/index.vue")
  },
  {
    name: "userspaces-settings-attendance",
    path: "/userspaces/settings/attendance",
    meta: { ...(indexZfnQjrAWUmMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/attendance/index.vue")
  },
  {
    name: "userspaces-settings-attendance-rules-id-edit",
    path: "/userspaces/settings/attendance/rules/:id()/edit",
    meta: { ...(editMR1BlbM47OMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/attendance/rules/[id]/edit.vue")
  },
  {
    name: "userspaces-settings-attendance-rules",
    path: "/userspaces/settings/attendance/rules",
    meta: { ...(indexQcQ360cGYhMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/attendance/rules/index.vue")
  },
  {
    name: "userspaces-settings-attendance-rules-new",
    path: "/userspaces/settings/attendance/rules/new",
    meta: { ...(newU91r7ww7GiMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/attendance/rules/new.vue")
  },
  {
    name: "userspaces-settings-attendance-seat_policy-global",
    path: "/userspaces/settings/attendance/seat_policy/global",
    meta: { ...(globalemNtDLHia4Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/attendance/seat_policy/global.vue")
  },
  {
    name: "userspaces-settings-bookables",
    path: "/userspaces/settings/bookables",
    meta: { ...(bookablesJQTWb7QF9dMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/bookables.vue")
  },
  {
    name: "userspaces-settings-data",
    path: "/userspaces/settings/data",
    meta: { ...(dataZOcvCPVDMgMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/data.vue")
  },
  {
    name: "userspaces-settings-desks",
    path: "/userspaces/settings/desks",
    meta: { ...(desksKxzHddkzdVMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/desks.vue")
  },
  {
    name: "userspaces-settings",
    path: "/userspaces/settings",
    meta: { ...(indexs3yW99PRi8Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/index.vue")
  },
  {
    name: "userspaces-settings-integrations",
    path: "/userspaces/settings/integrations",
    meta: { ...(integrationsncNyD3RVI5Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/integrations.vue")
  },
  {
    name: "userspaces-settings-kiosks",
    path: "/userspaces/settings/kiosks",
    meta: { ...(indexd78t3oDuZ0Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/kiosks/index.vue")
  },
  {
    name: "userspaces-settings-kiosks-profiles-id-edit",
    path: "/userspaces/settings/kiosks/profiles/:id()/edit",
    meta: { ...(editPs3MH5gsjDMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/kiosks/profiles/[id]/edit.vue")
  },
  {
    name: "userspaces-settings-kiosks-profiles",
    path: "/userspaces/settings/kiosks/profiles",
    meta: { ...(indexa7y2c4JywAMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/kiosks/profiles/index.vue")
  },
  {
    name: "userspaces-settings-kiosks-profiles-new",
    path: "/userspaces/settings/kiosks/profiles/new",
    meta: { ...(newqnFAwAzhgpMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/kiosks/profiles/new.vue")
  },
  {
    name: "userspaces-settings-kiosks-themes-id-edit",
    path: "/userspaces/settings/kiosks/themes/:id()/edit",
    meta: { ...(edit8mfpBtHIk4Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/kiosks/themes/[id]/edit.vue")
  },
  {
    name: "userspaces-settings-kiosks-themes",
    path: "/userspaces/settings/kiosks/themes",
    meta: { ...(indexG3UR25oWeLMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/kiosks/themes/index.vue")
  },
  {
    name: "userspaces-settings-kiosks-themes-new",
    path: "/userspaces/settings/kiosks/themes/new",
    meta: { ...(newquvdWD5GSjMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/kiosks/themes/new.vue")
  },
  {
    name: "userspaces-settings-rooms",
    path: "/userspaces/settings/rooms",
    meta: { ...(rooms4S54YOqUTCMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/rooms.vue")
  },
  {
    name: "userspaces-settings-usersimport",
    path: "/userspaces/settings/usersimport",
    meta: { ...(usersimportQh72R4OIkRMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/settings/usersimport.vue")
  },
  {
    name: "userspaces-users-id-edit",
    path: "/userspaces/users/:id()/edit",
    meta: { ...(editw261nhP8PSMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/[id]/edit.vue")
  },
  {
    name: "userspaces-users-id",
    path: "/userspaces/users/:id()",
    meta: { ...(indexvhVIfEZ5FmMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/[id]/index.vue")
  },
  {
    name: "userspaces-users-groups-id-edit",
    path: "/userspaces/users/groups/:id()/edit",
    meta: { ...(edit5zVjkH2JDKMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/groups/[id]/edit.vue")
  },
  {
    name: "userspaces-users-groups-id",
    path: "/userspaces/users/groups/:id()",
    meta: { ...(indexpNN0SyrEsFMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/groups/[id]/index.vue")
  },
  {
    name: "userspaces-users-groups-import-google",
    path: "/userspaces/users/groups/import/google",
    meta: { ...(googleJIdzPmdAsQMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/groups/import/google.vue")
  },
  {
    name: "userspaces-users-groups-import-microsoft",
    path: "/userspaces/users/groups/import/microsoft",
    meta: { ...(microsoftLRq44f1lzDMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/groups/import/microsoft.vue")
  },
  {
    name: "userspaces-users-groups",
    path: "/userspaces/users/groups",
    meta: { ...(indexAabbnP4APUMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/groups/index.vue")
  },
  {
    name: "userspaces-users-groups-new",
    path: "/userspaces/users/groups/new",
    meta: { ...(newle9I5p60WXMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/groups/new.vue")
  },
  {
    name: "userspaces-users",
    path: "/userspaces/users",
    meta: { ...(indexi7ogBEc304Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/index.vue")
  },
  {
    name: "userspaces-users-new",
    path: "/userspaces/users/new",
    meta: { ...(newyQihkX2wGBMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/users/new.vue")
  },
  {
    name: "userspaces-visitors",
    path: "/userspaces/visitors",
    meta: { ...(indexAMhGx9Dz00Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/visitors/index.vue"),
    children: [
  {
    name: "userspaces-visitors-index-id",
    path: ":id()",
    meta: { ...(_91id_93Aa9LeFVOc9Meta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/visitors/index/[id].vue")
  },
  {
    name: "userspaces-visitors-index-register",
    path: "register",
    meta: { ...(registerpKR0kfBcePMeta || {}), ...{"middleware":["policies"]} },
    component: () => import("/var/www/html/app/pages/userspaces/visitors/index/register.vue")
  }
]
  }
]