import Intercom from "@intercom/messenger-js-sdk";

export default defineNuxtPlugin((nuxtApp) => {
    const auth = useAuth()

    const subdomain = useRequestURL().hostname.split('.').shift()

    if (subdomain && subdomain !== 'accounts' && subdomain !== 'my') {
        let intercomConfig: any = {
            app_id: useRuntimeConfig().public.intercomAppId,
        }
        if (auth.user.value.email) {
            intercomConfig = {
                ...intercomConfig,
                user_id: auth.user.value.id,
                name: `${auth.user.value.fullname} (Workplace)`,
                first_name: auth.user.value.first_name,
                last_name: auth.user.value.last_name,
                email: auth.user.value.email,
            }
        }

        if (auth.user.value.roles) {
            intercomConfig = {
                ...intercomConfig,
                roles: auth.user.value.roles,
                space: auth.space.subdomain,
            }
        }

        Intercom(intercomConfig)
    }
})