<template>
  <article
    :class="noOverflow ? '' : 'overflow-y-visible overflow-hidden'"
    class="divide-y divide-gray-200 rounded-lg shadow flex flex-col"
    :style="backgroundColor"
  >
    <section class="px-4 py-5 sm:px-6" v-if="$slots.header">
      <!-- Header content goes here -->
      <slot name="header" />
    </section>
    <section class="px-4 py-5 sm:p-6 grow card-content overflow-y-visible">
      <!-- Content goes here -->
      <slot />
    </section>
    <section class="px-4 py-4 sm:px-6" v-if="$slots.footer">
      <!-- Footer content goes here -->
      <slot name="footer" />
    </section>
  </article>
</template>

<script setup lang="ts">
defineOptions({
  name: 'Card',
})

const props = defineProps<{
  forceBackground?: boolean
  noOverflow?: boolean
}>()

const subdomain = useSubdomain()
const backgroundColor = computed(() => {
  if (props.forceBackground) {
    return ''
  } else {
    return subdomain.value == 'my'
      ? 'background-color: #fafafa;'
      : 'background-color: white;'
  }
})
</script>
