import type { AuthSpace } from '@/utils/types/space'
import type { AuthUser } from '@/utils/types/user'

type LocalSessionData = {
  user?: AuthUser
  space: AuthSpace
}

export const useSession = () => {
  const event = useRequestEvent()
  let user = null
  let space = null

  if (event) {
    user = event.context.session.data.user
    space = event.context.session.data.space || {
      name: 'None',
      uuid: '',
      subdomain: 'accounts',
    }
  }

  return useState<LocalSessionData>('session', () => ({
    user,
    space,
  }))
}
