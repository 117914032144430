<template>
  <i
    :style="iconStyle"
    :class="[
      iconPack,
      iconPack !== 'material-icons' ? icon : '',
      iconClass,
      getBg,
      getBgSize,
      { round: round },
    ]"
    v-bind="filteredAttrs"
    class="vs-icon notranslate icon-scale"
    v-on="eventHandlers"
  >
    <FontAwesomeIcon v-if="iconPack !== 'material-icons'" :icon="icon" />
    <slot>{{ iconPack === 'material-icons' ? icon : '' }}</slot>
  </i>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const attrs = useAttrs()

const filteredAttrs = computed(() => {
  const { onClick, onMouseover, ...rest } = attrs
  return rest
})

const eventHandlers = computed(() => {
  const { onClick, onMouseover } = attrs
  return { onClick, onMouseover }
})
</script>

<script>
import _color from '../../utils/color.js'

export default {
  name: 'VsIcon',
  props: {
    icon: {
      default: '',
      type: [String, Array, Object],
    },
    iconPack: {
      default: 'fa',
      type: String,
    },
    color: {
      default: null,
      type: String,
    },
    bg: {
      default: null,
      type: String,
    },
    size: {
      default: null,
      type: String,
    },
    round: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    iconClass() {
      const classes = {}
      classes[this.size] = true
      if (_color.isColor(this.color)) {
        classes[`vs-icon-${this.color}`] = true
      }
      return classes
    },
    iconStyle() {
      const style = {
        width: /(px|em)/.test(this.size) ? this.size : null,
        height: /(px|em)/.test(this.size) ? this.size : null,
        'font-size': /(px|em)/.test(this.size) ? this.size : null,
        color: this.getColor,
        background: this.getBgColor,
      }
      return style
    },
    getBg() {
      const classes = {}
      if (_color.isColor(this.bg)) {
        classes[`con-vs-icon-bg-${this.bg}`] = true
      }
      return classes
    },
    getBgSize() {
      const classes = {}
      if (['small', 'medium', 'large'].includes(this.size)) {
        classes[`bg-${this.size}`] = true
        classes['vs-icon-bg'] = true
      }
      return classes
    },
    getColor() {
      return _color.isColor(this.color) ? this.color : this.color
    },
    getBgColor() {
      return _color.isColor(this.bg) ? this.bg : this.bg
    },
  },
}
</script>
