import type { RouterOptions } from '@nuxt/schema'
import type { RoutePolicy } from '@/composables/policies/types'

type BreadCrumbData = {
  name: string
  plural?: boolean
}

declare module 'vue-router' {
  interface RouteMeta {
    crumb: BreadCrumbData | false
    routePolicy?: RoutePolicy | false
  }
}

const staticSubdomains = ['accounts', 'admin', 'my', 'oauth']

const mapRoutes = (subdomain: string, routes: any[]) => {
  return routes.map((rt) => {
    if (rt.children) {
      rt.children = mapRoutes(subdomain, rt.children)
    }

    let newRouteName = rt.children
      ? rt.name
      : rt.name.replace(`${subdomain}-`, '')

    let newRoutePath = rt.path.replace(`/${subdomain}`, '')

    if (newRouteName === subdomain) {
      newRouteName = 'index'
      newRoutePath = '/'
    }

    return {
      ...rt,
      name: newRouteName,
      path: newRoutePath,
    }
  })
}

const makeUrl = (baseUrl: URL, topdomain: string) => {
  const newHost = `${topdomain}.${baseUrl.host.split('.').slice(1).join('.')}`

  const url = new URL(baseUrl)
  url.host = newHost
  url.protocol = baseUrl.protocol
  url.pathname = ''
  url.search = ''

  return url.toString().slice(0, -1)
}

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterOptions>{
  routes: (_routes) => {
    const { ssrContext } = useNuxtApp()
    const subdomain = useSubdomain()

    const accountsUrl = useAccountsUrl()
    const myComeenUrl = useMyComeenUrl()
    const dashboardUrl = useDashboardUrl()
    const adminUrl = useAdminUrl()

    const requestUrl = useRequestURL()

    accountsUrl.value = makeUrl(requestUrl, 'accounts')
    myComeenUrl.value = makeUrl(requestUrl, 'my')
    adminUrl.value = makeUrl(requestUrl, 'admin')

    const userSpaceSubdomain = useSession().value.space.subdomain
    dashboardUrl.value = makeUrl(requestUrl, userSpaceSubdomain)

    // Set subdomain from SSR context
    if (ssrContext?.event.context.subdomain)
      subdomain.value = ssrContext?.event.context.subdomain

    if (!subdomain.value) {
      return
    }

    if (staticSubdomains.includes(subdomain.value)) {
      // Static subdomain, routable pages are in corresponding folder (pages/:subdomain)
      const staticRoutes = _routes.filter((i) =>
        i.path.startsWith(`/${subdomain.value}`)
      )

      var availableRoutes = mapRoutes(subdomain.value, staticRoutes)
    } else {
      // User space subdomain, routable pages are in pages/userspaces
      const userSpaceRoutes = _routes.filter((i) =>
        i.path.startsWith('/userspaces')
      )

      var availableRoutes = mapRoutes('userspaces', userSpaceRoutes)
    }

    if (import.meta.dev) {
      const devRoutes = _routes.filter((route) => route.path.startsWith('/_'))
      availableRoutes.push(...devRoutes)
    }

    return availableRoutes
  },
}
