const DEFAULT_DURATION_MS = 5000

type Notification = {
  title: string,
  message?: string,
  type: 'success' | 'error',
  duration?: number,
}

const notifications = ref<Set<Notification>>(new Set())

export const useNotifier = () => {

  const clearNotification = (notification: Notification) => {
    notifications.value.delete(notification)
  }

  const notify = (notification: Notification) => {
    notifications.value.add(notification)

    setTimeout(
      () => clearNotification(notification),
      notification.duration ?? DEFAULT_DURATION_MS,
    )
  }

  const notifySuccess = (options: Omit<Notification, 'type'>) => notify({ ...options, type: 'success' })
  const notifyError = (options: Omit<Notification, 'type'>) => notify({ ...options, type: 'error' })

  return {
    notifications,
    notify,
    notifySuccess,
    notifyError,
  }
}
