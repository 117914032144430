<template lang="html">
  <div
    :style="style"
    :class="[
      `vs-textarea-${color}`,
      {
        'textarea-danger': counter ? modelValue && modelValue.length > counter : false,
        focusx: isFocus,
      },
    ]"
    class="vs-component vs-con-textarea"
  >
    <h4 v-if="label">
      {{ label }}
    </h4>

    <textarea
      :value="modelValue"
      v-bind="$attrs"
      class="vs-textarea"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="focus"
      @blur="blur"
    >
    </textarea>

    <div v-if="counter" class="count vs-textarea--count">
      {{ modelValue ? modelValue.length : 0 }} / {{ counter }}
    </div>
  </div>
</template>

<script>
import _color from "../../utils/color.js";
export default {
  name: "VsTextarea",
  inheritAttrs: false,
  props: {
    modelValue: {},
    label: {
      default: null,
      type: String,
    },
    color: {
      default: "primary",
      type: String,
    },
    counter: {
      default: null,
      type: [Number, String],
    },
    counterDanger: {
      default: false,
      type: Boolean,
    },
    height: {
      default: null,
      type: String,
    },
    width: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    style() {
      let style = {};
      style.border = `1px solid ${
        this.isFocus ? _color.getColor(this.color, 1) : "rgba(0, 0, 0,.08)"
      }`;
      style.height = this.height;
      style.width = this.width;

      return style;
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue && this.modelValue.length > this.counter) {
        this.$emit("update:counterDanger", true);
      } else {
        this.$emit("update:counterDanger", false);
      }
    },
  },
  methods: {
    focus() {
      this.isFocus = true;
      this.$emit("focus");
    },
    blur() {
      this.isFocus = false;
      this.$emit("blur");
    },
  },
};
</script>
