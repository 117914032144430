export const redirectToSubdomain = (
  subdomain: string,
  backTo?: string,
  route = ''
) => {
  // make URL
  let baseUrl = new URL(useDashboardUrl().value)
  //get protocol
  const protocol = baseUrl.protocol
  let hostnameParts = baseUrl.host.split('.')
  hostnameParts.shift()

  //if no specific back URL was given, go to correct space
  if (!backTo) {
    var newUrl = new URL(
      `${protocol}//${subdomain}.${hostnameParts.join('.')}` + route
    )
  } else {
    var newUrl = new URL(backTo)
  }

  if (newUrl.hostname.endsWith('comeen.io')) {
    return navigateTo(newUrl.toString(), {
      external: true,
    })
  } else {
    return navigateTo(useDashboardUrl().value, {
      external: false,
    })
  }
}
