import Loader from '@/components/comeen/utils/loading/Loader.vue'
import { render } from 'vue'

class LoaderInstance {
  parent: HTMLElement
  container: HTMLElement
  component: VNode

  constructor(container?: HTMLElement) {
    this.component = h(Loader)

    this.container = document.createElement('div')
    this.container.className = 'loading-element'
    this.container.style.position = 'relative'

    if (container) {
      this.parent = container
    } else {
      this.parent = document.body
    }
  }

  open() {
    this.parent.insertBefore(this.container, this.parent.firstChild)
    render(this.component, this.container)
  }

  close() {
    render(null, this.container)
    this.container.remove()
  }
}

class LoadingHelper {
  loaders: LoaderInstance[] = []

  open(container: HTMLElement) {
    const loader = new LoaderInstance(container)

    loader.open()
    this.loaders.push(loader)

    return loader
  }

  close(container: HTMLElement) {
    const loader = this.loaders.find((loader) => loader.parent === container)

    if (loader) {
      loader.close()

      return loader
    } else {
      return false
    }
  }
}

export const useLoading = () => {
  return new LoadingHelper()
}
