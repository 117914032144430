<template lang="html">
  <li v-show="visible" :data-text="text" class="vs-component" v-on="listeners">
    <button
      ref="item"
      :disabled="disabled"
      v-bind="$attrs"
      :style="styles"
      :class="{
        activex: parent.props.multiple
          ? getValue.indexOf(value) != -1
          : getValue == value,
        'con-icon': parent.props.multiple,
        disabledx: disabledx,
      }"
      class="vs-select--item"
      type="button"
      name="button"
      @keydown.backspace.prevent="backspace"
      @keydown.down.prevent="navigateOptions('next')"
      @keydown.up.prevent="navigateOptions('prev')"
      @keydown.enter.prevent="clickOption()"
    >
      <vs-icon
        v-if="parent.props.multiple"
        class="icon-item vs-select--item-icon"
        icon="check_circle"
      ></vs-icon>
      <span>{{ getText }}</span>
    </button>
  </li>
</template>

<script>
import _color from '../../utils/color.js'

export default {
  name: 'VsSelectItem',
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
    text: {
      default: null,
    },
  },
  data: () => ({
    hoverx: false,
    visible: true,
    getText: null,
    valueInputx: '',
  }),
  computed: {
    parent() {
      const instance = getCurrentInstance()
      return instance.parent.parent
    },
    children() {
      const instance = getCurrentInstance()
      return instance.parent.subTree.children
    },
    disabledx() {
      if (this.parent.props.multiple) {
        if (this.isActive) {
          return false
        } else {
          return (
            this.parent.props.maxSelected == this.parent.props.modelValue.length
          )
        }
      } else {
        return false
      }
    },
    isActive() {
      return this.parent.props.multiple
        ? this.getValue.indexOf(this.value) != -1
        : this.getValue == this.value
    },
    listeners() {
      return {
        ...this.$attrs,
        blur: (event) => {
          if (
            event.relatedTarget
              ? !event.relatedTarget.closest('.vs-select--options')
              : true
          ) {
            this.parent.parent.closeOptions()
          }
        },
        click: (event) => this.clickOption(event),
        mouseover: (event) => {
          this.$emit('mouseover', event)
          this.changeHover(true)
        },
        mouseout: (event) => {
          this.$emit('mouseover', event)
          this.changeHover(false)
        },
      }
    },
    styles() {
      return {
        background: this.isActive
          ? _color.getColor(this.parent.props.color, 0.1)
          : null,
        color: this.isActive
          ? _color.getColor(this.parent.props.color, 1)
          : null,
        fontWeight:
          this.isActive && this.value === this.parent.props.modelValue
            ? 'bold'
            : null,
      }
    },
    getValue() {
      if (this.parent.props.modelValue) {
        return this.parent.props.modelValue
      }
    },
  },
  watch: {
    'parent.data.active': function (e) {
      this.$nextTick(() => {
        if (
          this.parent.props.multiple
            ? this.getValue.indexOf(this.value) != -1
            : this.getValue == this.value
        ) {
          this.$emit('update:isSelected', true)
          this.getText = this.text
        } else {
          this.$emit('update:isSelected', false)
          this.getText = this.text
        }
      })
    },
    valueInputx() {
      if (this.visible) {
        let valueInputx = this.valueInputx.split(',')
        if (valueInputx[valueInputx.length - 1] == '') {
          this.getText = this.text
          return
        }
        let valuex = valueInputx[valueInputx.length - 1]
        var re = new RegExp(valuex, 'i')
        if (this.text.toUpperCase().indexOf(valuex.toUpperCase()) == 0) {
          valuex = this.MaysPrimera(valuex)
        }
        let text = this.text.replace(
          re,
          `<span class="searchx">${valuex}</span>`
        )
        this.getText = text
      } else {
        this.getText = this.text
      }
    },
  },
  created() {
    this.putValue()
    this.$nextTick(() => {
      if (
        this.parent.props.multiple
          ? this.getValue.indexOf(this.value) != -1
          : this.getValue == this.value
      ) {
        this.$emit('update:isSelected', true)
        this.getText = this.text
      } else {
        this.$emit('update:isSelected', false)
        this.getText = this.text
      }
    })
  },
  methods: {
    changeHover(booleanx) {
      this.hoverx = booleanx
    },
    MaysPrimera(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    backspace() {
      if (this.parent.props.autocomplete) {
        let valueInput = this.parent.refs.inputselect.value
        this.parent.refs.inputselect.value = valueInput.substr(
          0,
          valueInput.length - 1
        )
        this.parent.refs.inputselect.focus()
      }
    },
    navigateOptions(orientation) {
      let orientationObject = 'nextSibling',
        lengthx = 0

      function getNextLi(li, orientationObject) {
        if (li && li.localName == 'li') {
          let lix = li[orientationObject]
          if (li.style) {
            if (li.style.display == 'none') {
              return getNextLi(lix, orientationObject)
            } else {
              return li
            }
          } else {
            return li
          }
        } else {
          return false
        }
      }

      var children = this.children

      children.forEach((item) => {
        if (item.$children.length > 0) {
          children = [...children, ...item.$children]
        }
      })

      children = children.filter((item) => {
        return item.$children.length == 0 && item.$el.localName != 'span'
      })

      if (orientation == 'prev') {
        orientationObject = 'previousSibling'
        lengthx = children.length
      }
      let nextElement = getNextLi(
        this.$el[orientationObject],
        orientationObject
      )

      if (nextElement) {
        nextElement.querySelector('.vs-select--item').focus()
      } else {
        if (lengthx === children.length) lengthx--
        getNextLi(children[lengthx == 0 ? 1 : lengthx].$el, orientationObject)
          .querySelector('.vs-select--item')
          .focus()
      }
    },
    focusValue(index) {
      if (
        this.parent.props.multiple
          ? this.parent.props.modelValue.indexOf(this.value) != -1
          : this.value == this.parent.props.modelValue
      ) {
        if (!this.parent.props.autocomplete) {
          setTimeout(() => {
            this.$refs.item.focus()
          }, 50)
        }
      } else if (index === 0) {
        if (!this.parent.props.autocomplete) {
          setTimeout(() => {
            this.$refs.item.focus()
          }, 50)
        }
      }
    },
    putValue() {
      if (
        this.parent.props.modelValue &&
        this.value === this.parent.props.modelValue
      ) {
        this.parent.data.valuex = this.text
      }
    },
    clickOption() {
      if (this.disabledx) {
        return
      }
      let text = this.text

      if (!this.parent.parent.props.multiple) {
        this.parent.parent.data.active = false
        document.removeEventListener('click', this.parent.parent.clickBlur)
        this.parent.parent.data.valuex = text
        this.parent.parent.emit('input', this.value)
        this.parent.parent.ctx.changeValue()
      } else if (this.parent.parent.props.multiple) {
        this.parent.parent.data.valuex = text
        this.parent.parent.ctx.addMultiple(this.value)
      }
      const childrenArray = Array.from(this.children.data)
      childrenArray.map((item) => {
        item.valueInputx = ''
      })
      this.parent.data.valuex = text
    },

    // methods colors
    isColor() {
      return _color.isColor(this.color)
    },
  },
}
</script>
