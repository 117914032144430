import vsAlert from '@/components/3rd_party/vuesax/src/components/vsAlert/vsAlert.vue'
import vsAvatar from '@/components/3rd_party/vuesax/src/components/vsAvatar/vsAvatar.vue'
import vsBreadcrumb from '@/components/3rd_party/vuesax/src/components/vsBreadcrumb/vsBreadcrumb.vue'
import vsButton from '@/components/3rd_party/vuesax/src/components/vsButton/vsButton.vue'
import vsCard from '@/components/3rd_party/vuesax/src/components/vsCard/vsCard.vue'
import vsCheckbox from '@/components/3rd_party/vuesax/src/components/vsCheckBox/vsCheckBox.vue'
import vsChip from '@/components/3rd_party/vuesax/src/components/vsChip/vsChip.vue'
import vsChips from '@/components/3rd_party/vuesax/src/components/vsChip/vsChips.vue'

import vsCollapse from '@/components/3rd_party/vuesax/src/components/vsCollapse/vsCollapse.vue'
import vsCollapseItem from '@/components/3rd_party/vuesax/src/components/vsCollapse/vsCollapseItem.vue'

import vsDivider from '@/components/3rd_party/vuesax/src/components/vsDivider/vsDivider.vue'

import vsDropdown from '@/components/3rd_party/vuesax/src/components/vsDropDown/vsDropDown.vue'
import vsDropDownGroup from '@/components/3rd_party/vuesax/src/components/vsDropDown/vsDropDownGroup.vue'
import vsDropDownItem from '@/components/3rd_party/vuesax/src/components/vsDropDown/vsDropDownItem.vue'
import vsDropDownMenu from '@/components/3rd_party/vuesax/src/components/vsDropDown/vsDropDownMenu.vue'

import vsIcon from '@/components/3rd_party/vuesax/src/components/vsIcon/vsIcon.vue'
import vsInput from '@/components/3rd_party/vuesax/src/components/vsInput/vsInput.vue'
import vsInputNumber from '@/components/3rd_party/vuesax/src/components/vsInputNumber/vsInputNumber.vue'

import vsList from '@/components/3rd_party/vuesax/src/components/vsList/vsList.vue'
import vsListHeader from '@/components/3rd_party/vuesax/src/components/vsList/vsListHeader.vue'
import vsListItem from '@/components/3rd_party/vuesax/src/components/vsList/vsListItem.vue'

import vsNavbar from '@/components/3rd_party/vuesax/src/components/vsNavbar/vsNavbar.vue'


import vsPagination from '@/components/3rd_party/vuesax/src/components/vsPagination/vsPagination.vue'
import vsPopup from '@/components/3rd_party/vuesax/src/components/vsPopup/vsPopup.vue'
import vsProgress from '@/components/3rd_party/vuesax/src/components/vsProgress/vsProgress.vue'
import vsRadio from '@/components/3rd_party/vuesax/src/components/vsRadio/vsRadio.vue'

import vsSelect from '@/components/3rd_party/vuesax/src/components/vsSelect/vsSelect.vue'
import vsSelectItem from '@/components/3rd_party/vuesax/src/components/vsSelect/vsSelectItem.vue'
import vsSelectGroup from '@/components/3rd_party/vuesax/src/components/vsSelect/vsSelectGroup.vue'

import vsSidebar from '@/components/3rd_party/vuesax/src/components/vsSideBar/vsSidebar.vue'
import vsSidebarGroup from '@/components/3rd_party/vuesax/src/components/vsSideBar/vsSidebarGroup.vue'
import vsSidebarItem from '@/components/3rd_party/vuesax/src/components/vsSideBar/vsSidebarItem.vue'

import vsSpacer from '@/components/3rd_party/vuesax/src/components/vsSpacer/vsSpacer.vue'
import vsSwitch from '@/components/3rd_party/vuesax/src/components/vsSwitch/vsSwitch.vue'

import vsTable from '@/components/3rd_party/vuesax/src/components/vsTable/vsTable.vue'
import vsTd from '@/components/3rd_party/vuesax/src/components/vsTable/vsTd.vue'
import vsTh from '@/components/3rd_party/vuesax/src/components/vsTable/vsTh.vue'
import vsTr from '@/components/3rd_party/vuesax/src/components/vsTable/vsTr.vue'
import vsTrExpand from '@/components/3rd_party/vuesax/src/components/vsTable/vsTrExpand.vue'

import vsTab from '@/components/3rd_party/vuesax/src/components/vsTabs/vsTab.vue'
import vsTabs from '@/components/3rd_party/vuesax/src/components/vsTabs/vsTabs.vue'

import vsTextarea from '@/components/3rd_party/vuesax/src/components/vsTextarea/vsTextarea.vue'
import vsTooltip from '@/components/3rd_party/vuesax/src/components/vsTooltip/vsTooltip.vue'

import vsUpload from '@/components/3rd_party/vuesax/src/components/vsUpload/vsUpload.vue'
import viewUpload from '@/components/3rd_party/vuesax/src/components/vsUpload/viewUpload.vue'

import vsCol from '@/components/3rd_party/vuesax/src/layout/vsCol/vsCol.vue'
import vsRow from '@/components/3rd_party/vuesax/src/layout/vsRow/vsRow.vue'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('vsAvatar', vsAvatar)
  nuxtApp.vueApp.component('vsAlert', vsAlert)
  nuxtApp.vueApp.component('vsBreadcrumb', vsBreadcrumb)
  nuxtApp.vueApp.component('vsButton', vsButton)
  nuxtApp.vueApp.component('vsCard', vsCard)
  nuxtApp.vueApp.component('vsCheckbox', vsCheckbox)
  nuxtApp.vueApp.component('vsChip', vsChip)
  nuxtApp.vueApp.component('vsChips', vsChips)

  nuxtApp.vueApp.component('vsCollapse', vsCollapse)
  nuxtApp.vueApp.component('vsCollapseItem', vsCollapseItem)

  nuxtApp.vueApp.component('vsDivider', vsDivider)

  nuxtApp.vueApp.component('vsDropdown', vsDropdown)
  nuxtApp.vueApp.component('vsDropDownGroup', vsDropDownGroup)
  nuxtApp.vueApp.component('vsDropDownItem', vsDropDownItem)
  nuxtApp.vueApp.component('vsDropDownMenu', vsDropDownMenu)

  nuxtApp.vueApp.component('vsIcon', vsIcon)
  nuxtApp.vueApp.component('vsInput', vsInput)
  nuxtApp.vueApp.component('vsInputNumber', vsInputNumber)

  nuxtApp.vueApp.component('vsList', vsList)
  nuxtApp.vueApp.component('vsListHeader', vsListHeader)
  nuxtApp.vueApp.component('vsListItem', vsListItem)

  nuxtApp.vueApp.component('vsNavbar', vsNavbar)
  nuxtApp.vueApp.component('vsPagination', vsPagination)
  nuxtApp.vueApp.component('vsPopup', vsPopup)
  nuxtApp.vueApp.component('vsProgress', vsProgress)
  nuxtApp.vueApp.component('vsRadio', vsRadio)

  nuxtApp.vueApp.component('vsSelect', vsSelect)
  nuxtApp.vueApp.component('vsSelectItem', vsSelectItem)
  nuxtApp.vueApp.component('vsSelectGroup', vsSelectGroup)

  nuxtApp.vueApp.component('vsSidebar', vsSidebar)
  nuxtApp.vueApp.component('vsSidebarGroup', vsSidebarGroup)
  nuxtApp.vueApp.component('vsSidebarItem', vsSidebarItem)

  nuxtApp.vueApp.component('vsSpacer', vsSpacer)
  nuxtApp.vueApp.component('vsSwitch', vsSwitch)

  nuxtApp.vueApp.component('vsTable', vsTable)
  nuxtApp.vueApp.component('vsTd', vsTd)
  nuxtApp.vueApp.component('vsTh', vsTh)
  nuxtApp.vueApp.component('vsTr', vsTr)
  nuxtApp.vueApp.component('vsTrExpand', vsTrExpand)

  nuxtApp.vueApp.component('vsTab', vsTab)
  nuxtApp.vueApp.component('vsTabs', vsTabs)

  nuxtApp.vueApp.component('vsTextarea', vsTextarea)
  nuxtApp.vueApp.component('vsTooltip', vsTooltip)

  nuxtApp.vueApp.component('vsUpload', vsUpload)
  nuxtApp.vueApp.component('viewUpload', viewUpload)

  nuxtApp.vueApp.component('vsCol', vsCol)
  nuxtApp.vueApp.component('vsRow', vsRow)
})
