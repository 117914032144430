import { z } from 'zod'

const UserLocationSchema = z.object({
  id: z.number(),
  name: z.string(),
})
type UserLocation = z.infer<typeof UserLocationSchema>


export const UserNotificationsSettingsSchema = z.object({
  email: z.boolean(),
  facebook_workplace: z.boolean(),
  google_chat: z.boolean(),
  microsoft_teams: z.boolean(),
  onboarding_done: z.boolean(),
  phone: z.boolean(),
  slack: z.boolean(),
})
export type UserNotificationsSettings = z.infer<typeof UserNotificationsSettingsSchema>

export const UserSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string().nullable(),
  full_name: z.string(),
  manager_id: z.number().nullable(),
  email: z.string(),
  phone: z.string().nullable().optional(),
  avatar: z.string().nullable().optional(),
  timezone: z.string().nullable().optional(),
  locale: z.string().nullable().optional(),
  building: UserLocationSchema.nullable().optional(),
  floor: UserLocationSchema.nullable().optional(),
  area: UserLocationSchema.nullable().optional(),
  roles: z.array(z.string()).nullable().optional(),
  discarded_at: z.string().datetime().nullable().optional(),
  confirmed_at: z.string().datetime().nullable().optional(),
  recurring_presences: z.array(z.any()).nullable().optional(),
})
export type User = z.infer<typeof UserSchema>

export const UserRoleSchema = z.enum([
  'superadmin',
  'owner',
  'admin',
  'announcer',
  'user',
  'desk',
  'manager',
  'host',
])
export type UserRole = z.infer<typeof UserRoleSchema>

type UserSpace = {
  name: string
  subdomain: string
  uuid: string
  providers: string[]
}

export interface AuthUser extends User {
  id: number

  roles: UserRole[]
  space: UserSpace

  get isSuperAdmin(): boolean

  get isOwner(): boolean

  get isAdmin(): boolean

  get isAnnouncer(): boolean

  get isDashboard(): boolean

  get isDesk(): boolean

  get isManager(): boolean

  get hasAccessToTeammates(): boolean

  get fullname(): string

  get isLoggedIn(): boolean

  isAny(...roles: UserRole[]): boolean

  isAll(...roles: UserRole[]): boolean
}

abstract class BaseAuthUser implements AuthUser {
  id!: number
  first_name!: string
  last_name!: string
  full_name!: string
  email!: string
  avatar?: string | undefined

  manager_id!: number | null

  building?: UserLocation | undefined
  floor?: UserLocation | undefined
  area?: UserLocation | undefined

  roles!: UserRole[]

  space!: UserSpace

  get isSuperAdmin() {
    return this.isAll('superadmin')
  }

  get isOwner() {
    return this.isAll('owner')
  }

  get isAdmin() {
    return this.isAny('superadmin', 'owner', 'admin')
  }

  get isAnnouncer() {
    return this.isAny('superadmin', 'owner', 'announcer')
  }

  get isDashboard() {
    return this.isAny('superadmin', 'owner', 'user')
  }

  get isDesk() {
    return this.isAny('superadmin', 'owner', 'desk')
  }

  get isManager() {
    return this.isAny('superadmin', 'owner', 'manager')
  }

  get hasAccessToTeammates() {
    return this.isAny('superadmin', 'owner', 'desk', 'admin', 'user')
  }

  get fullname() {
    return this.first_name ? this.first_name + ' ' + this.last_name : this.email
  }

  get isLoggedIn() {
    return false
  }

  isAny(...roles: UserRole[]) {
    return roles.reduce(
      (acc = false, role) => acc || this.roles.includes(role),
      false,
    )
  }

  isAll(...roles: UserRole[]) {
    return roles.reduce(
      (acc = false, role) => acc && this.roles.includes(role),
      true,
    )
  }
}

export class LoggedInUser extends BaseAuthUser {
  constructor(user: any) {
    super()

    this.id = user.id
    this.first_name = user.first_name
    this.last_name = user.last_name
    this.full_name = user.full_name
    this.email = user.email
    this.avatar = user.avatar
    this.building = user.building
    this.floor = user.floor
    this.area = user.area
    this.roles = user.roles
    this.space = user.space
  }

  override get isLoggedIn() {
    return true
  }
}

export class DefaultUser extends BaseAuthUser {
  constructor() {
    super()

    this.id = -1
    this.first_name = 'default'
    this.last_name = 'user'
    this.full_name = 'Default User'
    this.email = 'default.user@comeen.com'
    this.roles = []
    this.space = {
      name: 'Default Space',
      subdomain: 'comeen',
      uuid: 'default',
      providers: [],
    }
  }

  override get isLoggedIn() {
    return false
  }
}
