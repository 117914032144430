<template>
  <div class="loader-container" aria-hidden="true">
    <Transition>
      <div class="loader-center" :class="loaderCenterClasses">
        <span class="loader"></span>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">

const props = withDefaults(
  defineProps<{
    blurBackground?: boolean
  }>(),
  {
    blurBackground: true,
  },
)

const loaderCenterClasses = computed(() => ({
  'blurred-background': props.blurBackground,
}))
</script>

<style scoped>
.blurred-background {
  background-color: rgba(255, 255, 255, 0.7);
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #626262 #626262 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #3f92cd #3f92cd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 32px;
  height: 32px;
  border-color: #626262 #626262 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
