<template>
  <button
    :type="props.type"
    class="rounded-lg font-semibold flex gap-2 items-center justify-center"
    :class="[classes, hasDefaultSlot ? 'px-7 py-2.5':'p-2.5']"
  >
    <FontAwesomeIcon :icon="icon" aria-hidden="true" v-if="icon" />

    <slot />
  </button>
</template>

<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

defineOptions({
  name: 'Button',
})

const props = withDefaults(
  defineProps<{
    type?: 'button' | 'submit' | 'reset'
    kind?: 'filled' | 'border' | 'flat'
    color?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'info'
    icon?: IconDefinition
  }>(),
  {
    type: 'button',
    kind: 'filled',
    color: 'primary',
  },
)

const slots = useSlots()
const hasDefaultSlot = computed(() => slots.default)

const buttonTextColorClass = computed(() => {
  if (props.kind === 'filled') {
    switch (props.color) {
      case 'secondary':
        return 'text-grey-darker disabled:text-grey-dark'
      default:
        return 'text-white'
    }
  }

  switch (props.color) {
    case 'primary':
      return 'text-comeen-primary-500'
    case 'success':
      return 'text-green-500'
    case 'danger':
      return 'text-comeen-danger'
    case 'warning':
      return 'text-yellow-500'
    case 'info':
      return 'text-cyan-400'
    case 'secondary':
    default:
      return 'text-grey-darker'
  }
})

const buttonBGColorClass = computed(() => {
  switch (props.color) {
    case 'primary':
      return 'bg-comeen-primary-500 disabled:bg-grey-light'
    case 'success':
      return 'bg-green-500 disabled:bg-grey-light'
    case 'danger':
      return 'bg-comeen-danger disabled:bg-grey-light'
    case 'warning':
      return 'bg-yellow-500 disabled:bg-grey-light'
    case 'info':
      return 'bg-cyan-400 disabled:bg-grey-light'
    case 'secondary':
    default:
      return 'bg-gray-50 disabled:bg-grey-lighter'
  }
})

const buttonHoverBGColorClass = computed(() => {
  if (props.kind === 'filled') {
    switch (props.color) {
      case 'primary':
        return 'hover:bg-comeen-primary-400'
      case 'success':
        return 'hover:bg-green-400'
      case 'danger':
        return 'hover:bg-comeen-danger-light'
      case 'warning':
        return 'hover:bg-yellow-400'
      case 'info':
        return 'hover:bg-cyan-300'
      case 'secondary':
      default:
        return 'hover:bg-gray-50'
    }
  } else {
    switch (props.color) {
      case 'primary':
        return 'hover:bg-comeen-primary-50'
      case 'success':
        return 'hover:bg-green-300'
      case 'danger':
        return 'hover:bg-comeen-danger-lighter'
      case 'warning':
        return 'hover:bg-yellow-300'
      case 'info':
        return 'hover:bg-cyan-200'
      case 'secondary':
      default:
        return 'hover:bg-gray-50'
    }
  }
})

const ringClass = computed(() => {
  switch (props.color) {
    case 'primary':
      return 'ring-comeen-primary-400'
    case 'success':
      return 'ring-green-300'
    case 'danger':
      return 'ring-comeen-danger-light'
    case 'warning':
      return 'ring-yellow-300'
    case 'info':
      return 'ring-cyan-200'
    case 'secondary':
    default:
      return 'ring-gray-300'
  }
})

const classes = computed(() => {
  let conditionalClasses = [toValue(buttonTextColorClass)]

  if (props.kind !== 'flat') {
    conditionalClasses.push('shadow-sm')
  }

  switch (props.kind) {
    case 'filled':
      conditionalClasses.push(
        toValue(buttonBGColorClass),
        toValue(buttonHoverBGColorClass),
      )
      break
    case 'border':
      conditionalClasses.push(
        'ring-1',
        'ring-inset',
        toValue(ringClass),
        toValue(buttonHoverBGColorClass),
      )
      break
    case 'flat':
      conditionalClasses.push(toValue(buttonHoverBGColorClass))
      break
  }

  return conditionalClasses.join(' ')
})
</script>
