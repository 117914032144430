<template>
  <div
    :class="{ 'open-item': maxHeight != '0px', disabledx: disabled }"
    class="vs-collapse-item"
    @mouseover="mouseover"
    @mouseout="mouseout"
  >
    <header class="vs-collapse-item--header" @click="toggleContent">
      <slot name="header"></slot>
      <span
        v-if="!notArrow"
        class="icon-header vs-collapse-item--icon-header pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
      >
        <vs-icon
          :icon="icon"
          class="mb-2 vs-icon notranslate icon-scale material-icons null"
          :color="iconColor"
          @click.prevent="switchIcon"
        ></vs-icon>
      </span>
    </header>
    <div
      ref="content"
      :style="styleContent"
      class="vs-collapse-item--content mt-2"
    >
      <div class="con-content--item">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VsCollapseItem',
  props: {
    open: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    notArrow: {
      default: false,
      type: Boolean,
    },
    sst: {
      default: false,
      type: Boolean,
    },
    iconColor: {
      type: String,
      required: false,
      default: null,
    },
    iconArrowUp: {
      type: [String, Array, Object],
      required: false,
      default: null,
    },
    iconArrowDown: {
      type: [String, Array, Object],
      required: false,
      default: null,
    },
  },
  data: () => ({
    maxHeight: '0px',
    // only used for sst
    dataReady: false,
    icon: 'fa-regular fa-chevron-down',
    parent: null,
  }),
  computed: {
    accordion() {
      return this.parent.ctx.accordion
    },
    openHover() {
      return this.parent.ctx.openHover
    },
    styleContent() {
      return {
        maxHeight: this.maxHeight,
      }
    },
  },
  watch: {
    maxHeight() {
      this.parent.ctx.emitChange()
    },
    ready(newVal, oldVal) {
      if (oldVal != newVal && newVal) {
        this.initMaxHeight()
      }
    },
  },
  created() {
    if (this.iconArrowDown) {
      this.icon = this.iconArrowDown
    }
  },
  mounted() {
    window.addEventListener('resize', this.changeHeight)
    const maxHeightx = this.$refs.content.scrollHeight
    if (this.open) {
      this.maxHeight = `${maxHeightx}px`
    }
    const instance = getCurrentInstance()
    this.parent = instance.parent
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeHeight)
  },
  methods: {
    switchIcon() {
      if (!this.iconArrowDown) {
        if (this.icon === 'fa-regular fa-chevron-down') {
          this.icon = 'fa-regular fa-chevron-up'
        } else {
          this.iconArrow = 'fa-regular fa-chevron-down'
        }
      } else {
        if (this.icon === this.iconArrowDown) {
          this.icon = this.iconArrowUp
        } else {
          this.icon = this.iconArrowDown
        }
      }
    },
    changeHeight() {
      if (this.$refs.content) {
        const maxHeightx = this.$refs.content.scrollHeight
        if (this.maxHeight != '0px') {
          this.maxHeight = `${maxHeightx}px`
        }
      }
    },
    toggleContent() {
      if (this.openHover || this.disabled) return

      if (this.accordion) {
        const instance = getCurrentInstance()
        this.parent.ctx.closeAllItems(this.$el)
      }

      if (this.sst && !this.dataReady) {
        this.$emit('fetch', {
          done: () => {
            this.initMaxHeight()
            this.dataReady = true
          },
        })
      } else {
        this.initMaxHeight()
      }
    },
    initMaxHeight() {
      const maxHeightx = this.$refs.content.scrollHeight
      if (this.maxHeight == '0px') {
        this.maxHeight = `${maxHeightx}px`
      } else {
        this.maxHeight = `0px`
      }
    },
    mouseover() {
      if (this.disabled) return
      let maxHeightx = this.$refs.content.scrollHeight
      if (this.openHover) {
        this.maxHeight = `${maxHeightx}px`
      }
    },
    mouseout() {
      if (this.openHover) {
        this.maxHeight = `0px`
      }
    },
    rotate() {
      if (this.rotate === 'rotate-0') {
        this.rotate = 'rotate-180'
      } else {
        this.rotate = 'rotate-0'
      }
    },
  },
}
</script>
