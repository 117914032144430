import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowDownArrowUp,
  faArrowDown,
  faMagnifyingGlass,
  faMap,
  faMinus,
  faPlus,
  faUser,
  faX,
  faSquareCheck,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faPenToSquare,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faArrowDown,
  faArrowDownArrowUp,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlass,
  faMap,
  faMinus,
  faSquareCheck,
  faPlus,
  faX,
  faUser,
  faPenToSquare,
  faTrash,
)

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon)
})
