import Dialog from '@/components/comeen/utils/popup/Dialog.vue'

type DialogType = Partial<InstanceType<typeof Dialog>>
const dialog = ref<DialogType>()

export const useDialog = () => {

  const removeDialogThenCall = (callback: Function | undefined) => {
    dialog.value = undefined
    if (callback) callback()
  }

  const openDialog = (dialogProps: DialogType) => {
    dialog.value = {
      ...dialogProps,
      cancel: () => removeDialogThenCall(dialogProps.cancel),
      accept: () => removeDialogThenCall(dialogProps.accept),
      onCancel: () => removeDialogThenCall(dialogProps.onCancel),
      onAccept: () => removeDialogThenCall(dialogProps.onAccept),
      onClose: () => removeDialogThenCall(dialogProps.onClose),
    }
  }

  return {
    dialog,
    openDialog,
  }
}
