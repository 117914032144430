import axios from 'axios'

export default defineNuxtPlugin({
  name: 'axios',
  setup() {
    const instance = axios.create()
    instance.interceptors.response.use(null, async (error) => {
      console.log("🍺 ~ instance.interceptors.response.use ~ error:", error)
      if (error.response && error.response.status === 429 && error.response.headers['retry-after']) {
        console.log('Rate limit exceeded, retrying in ' + error.response.headers['retry-after'] + ' seconds')
        await new Promise(
          resolve => setTimeout(resolve, parseInt(error.response.headers['retry-after']) * 1000)
        )
        return instance(error.config)
      } else {
        throw error
      }
    })

    return {
      provide: {
        axios: instance,
      },
    }
  },
})
