import { type AuthUser, DefaultUser, LoggedInUser } from '@/utils/types/user'
import { type AuthenticationResult } from '@azure/msal-browser'
import { apiFetch } from '@/utils/api/utils'

class AuthService {
  user: Ref<AuthUser>

  constructor() {
    if (useSession().value.user) {
      this.user = ref(new LoggedInUser(useSession().value.user))
    } else {
      this.user = ref(new DefaultUser())
    }
  }

  get roles() {
    return this.user.value.roles
  }

  get space() {
    return useSession().value.space
  }

  adminPasswordLogin(email: string, password: string) {
    return useAxios().post('auth/login', {
      admin: {
        email,
        password,
      },
    })
  }

  passwordLogin(email: string, password: string) {
    return apiFetch({
      url: '/auth/login',
      options: {
        method: 'POST',
        body: {
          user: {
            email,
            password,
          },
        },
      },
    })
  }

  logout() {
    clearNuxtState('session')

    return useAxios().delete('auth/logout')
  }

  googleLogin(
    oauthScopes = ['email', 'profile'],
    redirectURI = useAccountsUrl().value + '/login',
    state?: string
  ) {
    this.setAuthRedirect()

    let oauthURL = `https://accounts.google.com/o/oauth2/v2/auth`

    oauthURL += `?client_id=${useRuntimeConfig().public.googleClientId}`
    oauthURL += `&redirect_uri=${redirectURI}`
    oauthURL += `&scope=${oauthScopes.join(' ')}`
    oauthURL += `&response_type=id_token`
    oauthURL += `&nonce=${window.crypto.getRandomValues(new Uint32Array(1))[0]}`

    if (state) {
      oauthURL += `&state=${state}`
    }
    if (this.user.value.email) {
      oauthURL += `&login_hint=${this.user.value.email}`
    }

    oauthURL += `&prompt=consent`

    return Promise.resolve(window.location.assign(oauthURL))
  }

  googleLoginWithIDToken(idToken: string) {
    this.setAuthRedirect()

    return useAxios().post('auth/google/login', {
      id_token: idToken,
    })
  }

  async microsoftLogin() {
    const msalInstance = useMicrosoftAuth()
    await msalInstance.initialize()

    var loginPromise: Promise<AuthenticationResult | null>
    try {
      loginPromise = msalInstance.loginPopup()
    } catch (error) {
      loginPromise = msalInstance.handleRedirectPromise()
    }

    return loginPromise
      .then((response) => {
        return useAxios().post('auth/microsoft/login', {
          id_token: response?.idToken,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  slackLogin(
    oauthScopes = ['openid', 'email'],
    redirectURI = useAccountsUrl().value + '/login'
  ) {
    let oauthURL = 'https://slack.com/openid/connect/authorize'
    const {
      public: { slackClientId },
    } = useRuntimeConfig()

    oauthURL += `?client_id=${slackClientId}`
    oauthURL += `&redirect_uri=${redirectURI}/login`
    oauthURL += `&scope=${oauthScopes.join(' ')}`
    oauthURL += '&response_type=code'

    return navigateTo(oauthURL, {
      external: true,
    })
  }

  slackLoginWithCode(code: string) {
    return useAxios().post('auth/slack/login', {
      id_token: code,
    })
  }

  setAuthRedirect = () => {
    let params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    })

    if ((params as any).redirect_to) {
      localStorage.setItem('redirect', (params as any).redirect_to)
    }
  }

  redirectToSpace(space = { subdomain: '' }, backTo?: string, route = '') {
    redirectToSubdomain(space.subdomain, backTo, route)
  }

  refresh() {
    return apiFetch({
      url: `/auth/refresh`,
      options: {
        method: 'POST',
      },
    })
  }

  reload() {
    return apiFetch({
      url: `/auth/reload`,
      options: {
        method: 'POST',
      },
    })
  }
}

export default defineNuxtPlugin({
  name: 'auth',
  dependsOn: ['axios'],
  setup() {
    const authService = new AuthService()

    return {
      provide: {
        auth: authService,
      },
    }
  },
})
