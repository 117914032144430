<template>
  <div class="h-screen w-screen flex flex-col md:justify-center">
    <Card class="mx-auto md:my-auto w-full flex-grow md:flex-grow-0 md:w-2/3">
      <template #header>
        <h1 class="font-bold text-6xl">{{ error?.statusCode }}</h1>
        <p class="text-2xl">
          {{ t(`errorPage.statuses.${error?.statusCode}`) }}
        </p>
      </template>
      <p class="text-lg font-semibold mb-4">{{ error?.message }}</p>
      <div v-if="showStackTrace" class="overflow-y-auto md:max-h-64">
        <div class="px-4" v-html="error?.stack" v-if="error?.stack"></div>
        <p class="px-4" v-else>{{ t('errorPage.noStackTrace') }}</p>
      </div>
      <template #footer>
        <div class="flex justify-end">
          <Button @click="handleError">{{
              t('errorPage.actions.goBack')
            }}
          </Button>
        </div>
      </template>
    </Card>
  </div>
</template>

<i18n lang="json" locale="en">
{
  "errorPage": {
    "statuses": {
      "default": "An error occurred",
      "403": "Unauthorized",
      "404": "Page not found",
      "422": "Unprocessable entity",
      "500": "Server error"
    },
    "actions": {
      "goBack": "Go back home"
    },
    "noStackTrace": "No stack trace available"
  }
}
</i18n>

<i18n lang="json" locale="fr">
{
  "errorPage": {
    "statuses": {
      "default": "Une erreur s'est produite",
      "403": "Accès interdit",
      "404": "Page non trouvée",
      "422": "Entité non traitable",
      "500": "Erreur serveur"
    },
    "actions": {
      "goBack": "Retourner à l'accueil"
    },
    "noStackTrace": "Pas de trace de pile disponible"
  }
}
</i18n>

<script setup lang="ts">
import type { NuxtError } from '#app'
import Card from '@/components/comeen/utils/layout/Card.vue'
import Button from '@/components/comeen/utils/ui/Button.vue'

const props = defineProps({
  error: Object as () => NuxtError,
})

const { t } = useI18n()

const showStackTrace = import.meta.dev

const handleError = () => clearError({ redirect: '/' })
</script>
