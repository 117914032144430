<template>
  <transition name="tr-expand">
    <tr
      v-if="active"
      class="tr-expand"
    >
      <td :colspan="colspan">
        <div class="content-tr-expand">
          <slot></slot>

          <button
            v-if="close"
            class="tr-expand--close"
            @click="$emit('click', $event)"
          >
            <vs-icon :icon="expandIcon" />
          </button>
        </div>
      </td>
    </tr>
  </transition>
</template>

<script>
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'VsTrExpand',
  props: {
    close: {
      type: Boolean,
      default: false
    },
    colspan:{
      default: 1,
      type: Number
    }
  },
  data:() => ({
    active: false,
    expandIcon: faChevronDown
  }),
  mounted() {
    this.active = true
  }
}
</script>
