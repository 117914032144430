<template>
  <td ref="td" :class="{ 'td-edit': hasEditSlot }" class="td vs-table--td">
    <span @click="clicktd">
      <vs-icon v-if="hasEditSlot" class="icon-edit" icon="edit"> </vs-icon>
      <slot></slot>
      <span v-if="hasEditSlot" class="empty">
        {{ data ? '' : 'Empty' }}
      </span>
    </span>

    <transition name="td">
      <div v-if="activeEdit" class="con-edit-td">
        <slot name="edit"></slot>
        <vs-button
          icon="fa-solid fa-x"
          size="small"
          color="primary"
          type="flat"
          @click="close"
        ></vs-button>
      </div>
    </transition>
  </td>
</template>

<script>
export default {
  name: 'VsTd',
  props: {
    data: {
      default: null,
    },
  },
}
</script>

<script setup>
import trExpand from './vsTrExpand.vue'

const data = ref(null)
const activeEdit = ref(false)

const clicktd = (evt) => {
  const { props, ctx } = getCurrentInstance()
  const { slots } = ctx
  if (slots.edit) {
    const tr = evt.target.closest('tr')
    if (!activeEdit.value) {
      const trExpandComponent =
        getCurrentParentInstance().components[trExpand.name]
      const instance = h(
        trExpandComponent,
        { colspan: props.colspan, close: true },
        slots.edit()
      )
      const nuevoParrafo = document.createElement('tr')
      nuevoParrafo.appendChild(instance)
      insertAfter(tr, nuevoParrafo)
      activeEdit.value = true
      setTimeout(() => {
        window.addEventListener('click', closeEdit)
      }, 20)
    }
  }
}

const closeEdit = (evt) => {
  if (
    !evt.target.closest('.tr-expand') &&
    !evt.target.closest('.vs-select--options')
  ) {
    close()
  }
}

const close = () => {
  const { refs } = getCurrentInstance()
  const tr = refs.td.closest('tr')
  activeEdit.value = false
  tr.parentNode.removeChild(tr.nextSibling)
  window.removeEventListener('click', closeEdit)
}

const insertAfter = (e, i) => {
  if (e.nextSibling) {
    e.parentNode.insertBefore(i, e.nextSibling)
  } else {
    e.parentNode.appendChild(i)
  }
}

const hasEditSlot = !!getCurrentInstance().slots.edit
</script>
