export default {
  "c-default": () => import("/var/www/html/app/layouts/c-default.vue"),
  default: () => import("/var/www/html/app/layouts/default.vue"),
  "default-breadcrumbs": () => import("/var/www/html/app/layouts/default/Breadcrumbs.vue"),
  "default-profile-dropdown": () => import("/var/www/html/app/layouts/default/ProfileDropdown.vue"),
  "default-sidebar": () => import("/var/www/html/app/layouts/default/Sidebar.vue"),
  "default-sidebar-group": () => import("/var/www/html/app/layouts/default/SidebarGroup.vue"),
  "default-sidebar-helpcenter-link": () => import("/var/www/html/app/layouts/default/SidebarHelpcenterLink.vue"),
  "default-sidebar-item": () => import("/var/www/html/app/layouts/default/SidebarItem.vue"),
  fullpage: () => import("/var/www/html/app/layouts/fullpage.vue"),
  my: () => import("/var/www/html/app/layouts/my.vue"),
  "my-sidebar": () => import("/var/www/html/app/layouts/my/Sidebar.vue"),
  "my-sidebar-dashboard-link": () => import("/var/www/html/app/layouts/my/SidebarDashboardLink.vue"),
  "my-sidebar-settings-link": () => import("/var/www/html/app/layouts/my/SidebarSettingsLink.vue"),
  "my-today-sidebar": () => import("/var/www/html/app/layouts/my/TodaySidebar.vue"),
  neutralpage: () => import("/var/www/html/app/layouts/neutralpage.vue"),
  onboarding: () => import("/var/www/html/app/layouts/onboarding.vue")
}