import { PublicClientApplication } from '@azure/msal-browser'

export default defineNuxtPlugin({
  name: 'msal',
  async setup() {
    const microsoftClientId = useRuntimeConfig().public.microsoftClientId
    const msalInstance = new PublicClientApplication({
      auth: {
        clientId: microsoftClientId,
        authority: 'https://login.microsoftonline.com/organizations/',
        redirectUri: useAccountsUrl().value + '/login',
      },
    })

    return {
      provide: {
        msal: msalInstance,
      },
    }
  },
})
