import revive_payload_client_4sVQNw7RlN from "/var/www/html/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/html/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/html/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/html/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/html/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/html/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/html/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/html/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/var/www/html/app/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/var/www/html/app/.nuxt/sentry-client-config.mjs";
import primevue_plugin_egKpok8Auk from "/var/www/html/app/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/var/www/html/app/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/var/www/html/app/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/html/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/html/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/var/www/html/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/var/www/html/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import auth_vT9JWWT9pN from "/var/www/html/app/plugins/auth.ts";
import axios_QMFgzss1s4 from "/var/www/html/app/plugins/axios.ts";
import debug_client_j3mkIyXYPA from "/var/www/html/app/plugins/debug.client.ts";
import fontawesome_cn2c4tOOHz from "/var/www/html/app/plugins/fontawesome.ts";
import intercom_client_vr7NPky0pK from "/var/www/html/app/plugins/intercom.client.ts";
import leaflet_zoom_client_Z4WPkzaABE from "/var/www/html/app/plugins/leaflet.zoom.client.ts";
import loading_GxQ2Z9Goxa from "/var/www/html/app/plugins/loading.ts";
import msal_client_gxY03bskzg from "/var/www/html/app/plugins/msal.client.ts";
import posthog_client_fUucxKWhZE from "/var/www/html/app/plugins/posthog.client.ts";
import pusher_client_No5cywskfC from "/var/www/html/app/plugins/pusher.client.ts";
import sentry_client_shVUlIjFLk from "/var/www/html/app/plugins/sentry.client.ts";
import vCalendar_jfuLUQ1Ymq from "/var/www/html/app/plugins/vCalendar.ts";
import vuesax_eTweNMfFEG from "/var/www/html/app/plugins/vuesax.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  auth_vT9JWWT9pN,
  axios_QMFgzss1s4,
  debug_client_j3mkIyXYPA,
  fontawesome_cn2c4tOOHz,
  intercom_client_vr7NPky0pK,
  leaflet_zoom_client_Z4WPkzaABE,
  loading_GxQ2Z9Goxa,
  msal_client_gxY03bskzg,
  posthog_client_fUucxKWhZE,
  pusher_client_No5cywskfC,
  sentry_client_shVUlIjFLk,
  vCalendar_jfuLUQ1Ymq,
  vuesax_eTweNMfFEG
]