import type { NuxtApp } from '#app'
import Pusher from 'pusher-js'

type PusherInstance = Pusher

const injectPusher = (app: NuxtApp): Promise<PusherInstance> => {
  return new Promise((resolve, reject) => {
    try {
      Pusher.logToConsole = process.env.NODE_ENV === 'development'

      if (!app.$pusher) {

        var socket = new Pusher(app.$config.public.pusherAppKey, {
          cluster: app.$config.public.pusherCluster,
          forceTLS: true,
        })

        app.provide('pusher', socket)

        resolve(socket)
      } else {
        // @ts-ignore
        resolve(app.$pusher)
      }
    } catch (error) {
      reject(error)
    }
  })
}

export default defineNuxtPlugin({
  name: 'pusher',
  setup() {
    const nuxtApp = useNuxtApp()

    const loadPusher: () => Promise<PusherInstance> = injectPusher.bind(
      null,
      nuxtApp
    )

    return {
      provide: {
        loadPusher,
      },
    }
  },
})
