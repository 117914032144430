export default defineNuxtPlugin({
  name: 'debug',
  dependsOn: ['axios', 'auth'],
  setup() {
    if (import.meta.dev) {
      const nuxtApp = useNuxtApp()
      window.$nuxt = nuxtApp

      console.debug('Debug plugin loaded')
    }
  },
})
