<template>
  <Popup @close="emit('close')" v-model:opened="opened">
    <div class="pb-4 sm:pb-4">
      <div class="sm:flex sm:items-start">
        <div
          v-if="icon"
          class=" mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
          :class="color==='danger'? 'bg-comeen-danger' : 'bg-comeen-primary-200'"
        >
          <FontAwesomeIcon
            :icon="icon"
            class="text-2xl shrink-0 text-white"
            aria-hidden="true"
          />
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <DialogTitle
            as="h3"
            class="text-base font-semibold leading-6 text-gray-900"
          >
            {{ title }}
          </DialogTitle>

          <div class="mt-2">
            <p class="text-sm text-gray-500">
              {{ text }}
            </p>

            <slot />
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 py-3 flex gap-2 sm:flex-row-reverse sm:px-2">
      <Button
        :color="color==='danger' ? 'danger' : 'primary'"
        type="button"
        @click="onAccept"
        :disabled="!isValid"
      >
        {{ acceptText ?? t('ui.common.confirm') }}
      </Button>
      <Button
        v-if="type !== 'alert'"
        color="secondary"
        type="button"
        @click="onCancel"
        ref="cancelButtonRef"
      >
        {{ cancelText ?? t('ui.common.cancel') }}
      </Button>
    </div>
    <div v-if="loading" class="absolute bg-white/50 inset-0">
      <Loader />
    </div>
  </Popup>
</template>

<script setup lang="ts">
import { DialogTitle } from '@headlessui/vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import Popup from '@/components/comeen/utils/popup/Popup.vue'
import Loader from '@/components/comeen/utils/loading/Loader.vue'
import Button from '@/components/comeen/utils/ui/Button.vue'

const { t } = useI18n()

const emit = defineEmits(['close', 'accept', 'cancel'])

export type DialogProps = {
  type?: 'confirm' | 'alert'
  color?: 'danger' | 'info'
  title?: string
  text?: string
  icon?: IconDefinition | false
  accept?: Function
  acceptText?: string
  cancel?: Function
  cancelText?: string
  loading?: boolean
  isValid?: boolean
}

const props = withDefaults(defineProps<DialogProps>(), {
  type: 'alert',
  color: 'info',
  title: 'Dialog',
  text: 'Information',
  icon: () => faExclamationTriangle,
  accept: undefined,
  cancel: undefined,
  isValid: true,
})

const opened = defineModel('opened', {
  type: Boolean,
  default: false,
})

const onAccept = () => {
  if (props.accept) {
    props.accept()
  }

  emit('accept')
  opened.value = false
}

const onCancel = () => {
  if (props.cancel) {
    props.cancel()
  }

  emit('cancel')
  opened.value = false
}
</script>
