import { z } from 'zod'
import type { SpaceProviderDomains } from './integrations'

const BaseSpaceSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  subdomain: z.string(),
})
export type BaseSpace = z.infer<typeof BaseSpaceSchema>

export const SpaceSchema = BaseSpaceSchema.extend({
  logo: z.string(),
  enable_notifications_channel_choice: z.boolean(),
  providers: z.array(z.string()),
})
export type Space = z.infer<typeof SpaceSchema>

export type AuthSpace = Space & {
  provider_domains: SpaceProviderDomains
  url: string
}
