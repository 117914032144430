import posthog from 'posthog-js'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { postHogKey, postHogHost },
  } = useRuntimeConfig()

  if (!postHogKey) {
    return
  }

  const posthogClient = posthog.init(postHogKey, {
    api_host: postHogHost,
    capture_pageview: false,
    autocapture: false,
    loaded: () => {
      const { user } = useAuth()
      if (user.value?.id) {
        posthog.identify('' + user.value.id)
        posthog.reloadFeatureFlags()
      }
    },
  })

  posthog.onFeatureFlags((flags) => {
    console.log(flags)
  })

  // Make sure that pageviews are captured with each route change
  const router = useRouter()
  router?.afterEach((to) => {
    nextTick(() => {
      if (
        !(
          to.fullPath.startsWith('/meeting_rooms/dashboards/') &&
          to.fullPath !== '/meeting_rooms/dashboards/new'
        )
      ) {
        posthog.capture('$pageview', {
          current_url: to.fullPath,
        })
      }
    })
  })

  return {
    provide: {
      posthog: posthogClient,
    },
  }
})
